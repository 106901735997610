import React, { useContext, useEffect, useState } from 'react';
import Button from '@components/Button';
import { Accordion, AccordionDetails, AccordionSummary, Fab, IconButton } from '@mui/material';
import { Close, ExpandMore } from '@mui/icons-material';
import LocalStorageService from '@util/LocalStorageService';
import Logger from '@util/Logger';
import LoggerSettings from '@components/devtools/LoggerSettings';
import { getConfig } from '@util/config';
import AuthTokenTools from '@components/devtools/AuthTokenTools';
import { MutatorOptions, useSWRConfig } from 'swr';
import FeatureToggleSettings from '@components/devtools/FeatureToggleSettings';
import ThemeSettings from '@components/devtools/ThemeSettings';
import { AuthContext } from '@contexts/AuthContext';
import TrialDevTools from '@components/devtools/TrialDevTools';
import Logo from '@components/Logo';
import useMatchMutate from '@hooks/useMatchMutate';

const Config = getConfig();
declare global {
    interface Window {
        PlutoDev?: {
            on: () => void;
            off: () => void;
            getTokens: () => unknown;
            revalidatePath: (path: string, options?: MutatorOptions) => unknown;
            revalidatePathIncludes: (path: string, options?: MutatorOptions) => unknown;
            revalidatePathStatsWith: (path: string, options?: MutatorOptions) => unknown;
        };
    }
}

const logger = Logger.make('PlutoDevTools');

const PlutoDevTools = () => {
    const swrConfig = useSWRConfig();
    const authContext = useContext(AuthContext);
    const [isDev, setIsDev] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const { pathEqualsIgnoreQueryMutate, startsWithMutate } = useMatchMutate();
    const setDevToolsOpen = (open: boolean) => {
        setIsDev(open);
        LocalStorageService.setDevMode(open);
    };

    useEffect(() => {
        setIsDev(LocalStorageService.isDevMode());

        const storageHandler = () => {
            setIsDev(LocalStorageService.isDevMode());
        };
        window.addEventListener('storage', storageHandler);
        window.PlutoDev = {
            on: () => setDevToolsOpen(true),
            off: () => setDevToolsOpen(false),
            getTokens: () => authContext.getTokens(),
            revalidatePath: (path, options) => {
                logger.debug('revalidating path: ', path, options);
                return pathEqualsIgnoreQueryMutate(path, undefined, options);
            },
            revalidatePathIncludes: (path, options) => {
                logger.debug('revalidating path: ', path, options);
                return pathEqualsIgnoreQueryMutate(path, undefined, options);
            },
            revalidatePathStatsWith: (path, options) => {
                logger.debug('revalidating path starts with: ', path, options);
                return startsWithMutate(path, undefined, options);
            },
        };
        return () => {
            window.removeEventListener('storage', storageHandler);
            window.PlutoDev = undefined;
        };
    }, []);

    if (!isDev) {
        return null;
    }

    if (minimized) {
        return (
            <div className="fixed bottom-28 right-4 z-[99999] md:bottom-24">
                <Fab
                    sx={{ '& .MuiFab-label': { lineHeight: 1 } }}
                    variant="circular"
                    onClick={() => setMinimized(false)}
                    color="default"
                >
                    <Logo width={20} />
                </Fab>
            </div>
        );
    }

    return (
        <div className="fixed bottom-24 left-2 right-2 z-[99999] w-auto max-w-lg rounded-xl border-2 border-indigo-300 bg-indigo-100 px-4 py-4 drop-shadow-xl sm:left-auto sm:right-4 sm:w-full md:bottom-4 print:hidden">
            <div className="text-default">
                <span className="absolute right-2 top-2 flex space-x-1">
                    <IconButton
                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                        onClick={() => setMinimized(true)}
                        size="small"
                        title="Minimize Dev Tools"
                    >
                        <ExpandMore />
                    </IconButton>
                    <IconButton
                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                        onClick={() => setDevToolsOpen(false)}
                        size="small"
                        title="Exit Dev Tools"
                    >
                        <Close />
                    </IconButton>
                </span>
                <div className="space-y-4">
                    <h3 className="flex items-center space-x-2 text-xl font-semibold">
                        <Logo width={50} height={40} dark /> Pluto Dev Tools
                    </h3>
                    <div className="-mx-4 max-h-screen-75 overflow-auto px-4 pb-4">
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} className="hover:bg-indigo-200/20">
                                <p className="text-lg font-semibold">Auth Tokens</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AuthTokenTools />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} className="hover:bg-indigo-200/20">
                                <p className="text-lg font-semibold">Feature Toggles</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FeatureToggleSettings />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} className="hover:bg-indigo-200/20">
                                <p className="text-lg font-semibold">Logger Settings</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LoggerSettings />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} className="hover:bg-indigo-200/20">
                                <p className="text-lg font-semibold">SWR Cache</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        console.log(swrConfig.cache);
                                    }}
                                >
                                    Print cache to console
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} className="hover:bg-indigo-200/20">
                                <p className="text-lg font-semibold">Theme Settings</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ThemeSettings />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} className="hover:bg-indigo-200/20">
                                <p className="text-lg font-semibold">Trial Overrides</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TrialDevTools />
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div className="text-sm">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="pr-2 font-semibold">Branch</span>
                                    </td>
                                    <td className="font-mono text-xs">{Config.branchName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="pr-2 font-semibold">Commit</span>
                                    </td>
                                    <td className="font-mono text-xs">{Config.appVersion ?? '(not set)'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlutoDevTools;
