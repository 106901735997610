import Button, { ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';

export type ButtonVariant = 'text' | 'outlined' | 'contained';
type LinkRef = HTMLButtonElement;

export type LoadableButtonProps = Omit<ButtonProps, 'color'> & {
    loading?: boolean;
    btnRef?: ForwardedRef<LinkRef>;
    color?: 'white' | 'danger' | 'gray' | ButtonProps['color'];
    textClass?: string;
    target?: string;
};

const StyledButton = Button;
const LoadableButton = ({
    className,
    loading,
    children,
    btnRef,
    color,
    textClass = 'tracking-normal',
    ...props
}: LoadableButtonProps) => {
    const styles = {
        smallClass: 'py-[4px] px-[15px]',
        rootPrimary:
            'bg-[#3343AB] text-white hover:bg-[rgb(35, 46, 119)] hover:border=[rgb(35, 46, 119)] hover:text-[rgb(35, 46, 119)] ease transition',
        rootSecondary:
            'bg-[#616EDF] text-white hover:bg-[#5765cf] hover:border=[#5765cf] hover:text-[rgb(35, 46, 119)] ease transition',
        outlinedPrimary:
            'bg-transparent border-[#3343AB] text-[#3343AB] transition ease hover:bg-[rgba(51, 67, 171)]/50 hover:border-[rgb(35, 46, 119)] hover:text-[rgb(35, 46, 119)]',
        outlinedSecondary:
            'bg-transparent border-[#616EDF] text-[#616EDF] transition ease hover:bg-[rgba(51, 67, 171)]/50 hover:border-[#5765cf] hover:text-[#5765cf]',
        rootGray: 'bg-[rgb(107 114 128] transition ease hover:bg-[rgb(75 85 99)]',
        outlinedWhite: 'bg-transparent border-white text-white transition ease hover:bg-white hover:border-[#3343AB]',
        outlinedRed:
            'bg-transparent border-[#994D4D] text-[#994D4D] hover:text-[#994D4D] transition ease hover:bg-[#994D4D]/10 hover:border-[#994D4D]',
        outlinedGray:
            'bg-transparent border-[rgb(107 114 128)] text-[rgb(107 114 128)] transition ease hover:bg-white/50 hover:text-[rgb(55 65 81)]',
    };

    const useWhiteSpinner = props.variant === 'contained' && !props.disabled;
    const spinnerStyles = { color: useWhiteSpinner ? 'white' : undefined };
    const colorProp = color === 'white' || color === 'danger' || color === 'gray' ? undefined : color;

    const $loader = (
        <span className="absolute flex items-center">
            <CircularProgress variant="indeterminate" size={20} color={'primary'} style={spinnerStyles} />
        </span>
    );

    const buildClassName = () => {
        let fullClassName = '';
        if (props.variant === 'outlined') {
            if (color === 'white') fullClassName = fullClassName + styles.outlinedWhite;
            if (color === 'gray') fullClassName = fullClassName + styles.outlinedGray;
            if (color === 'danger') fullClassName = fullClassName + styles.outlinedRed;
            if (color === 'primary') fullClassName = fullClassName + styles.outlinedPrimary;
            if (color === 'secondary') fullClassName = fullClassName + styles.outlinedSecondary;
        }
        if (color === 'primary' && props.variant === 'contained') fullClassName = fullClassName + styles.rootPrimary;
        if (color === 'gray' && props.variant === 'contained') fullClassName = fullClassName + styles.rootGray;
        if (color === 'secondary' && props.variant === 'contained')
            fullClassName = fullClassName + styles.rootSecondary;

        if (props.size === 'small') fullClassName = styles.smallClass + ' ' + fullClassName;
        if (className) fullClassName = className + ' ' + fullClassName;
        return fullClassName;
    };

    return (
        <StyledButton {...props} color={colorProp} ref={btnRef} className={buildClassName()}>
            <span className={cn('flex items-center normal-case', textClass, { 'opacity-0': loading })}>{children}</span>
            {loading && $loader}
        </StyledButton>
    );
};

const RefButton = forwardRef<LinkRef, LoadableButtonProps>((props, ref) => <LoadableButton {...props} btnRef={ref} />);

export default RefButton;
