import Logger from '@util/Logger';
import { format, differenceInDays } from 'date-fns';

const logger = Logger.make('DateUtil');

/**
 * Number of milliseconds in a day
 * @type {number}
 */
export const DAY_MS = 24 * 60 * 60 * 1000;

/**
 * Parses a date input (string or number), or if not present, adds the defaultDuration to the current date
 * @param {string | number | undefined | null} input - the expiration date to parse
 * @param {number | null} [defaultDuration=null] If no input is provided, the default duration to add to the current date.
 * @return {Date | null}
 */
export function getExpirationDate(
    input: string | number | undefined | null,
    defaultDuration: number | null = null,
): Date | null {
    const start = new Date();
    let expiration: Date | null = null;
    if (input) {
        expiration = new Date(input);
    } else if (defaultDuration) {
        expiration = new Date(start.getTime() + defaultDuration);
    }

    if (expiration === null) {
        logger.warn('Unable to get an expiration date - no input or defaultDuration was provided');
        return null;
    }

    const dateTime = expiration.getTime();
    if (isNaN(dateTime)) {
        logger.error(new Error(`Unable to parse expiration date from input "${input}". Date is not a valid date.`));
        return null;
    }

    return expiration;
}

/** Check if the date input is a valid date*/
export const isValidDate = (input: Date | string | null | undefined): input is Date | string => {
    try {
        if (!input) {
            return false;
        }

        const date = new Date(input);
        if (Number.isNaN(date.getTime())) {
            return false;
        }
        return (date as unknown) instanceof Date;
    } catch (e) {
        const error = new Error(`Failed to parse date input: ${input}`);
        error.cause = error;
        logger.error(error);
        return false;
    }
};

export const toValidDate = (input: Date | string | null | undefined): Date | null => {
    if (input && isValidDate(input)) {
        return new Date(input);
    }
    return null;
};

export enum DateFormat {
    DATE_SHORT = 'M/d/yyy',
    DATE_TIME = 'M/d/yyy, p',
    DATE_FRIENDLY = 'MMMM d, yyy',
    MONTH_YEAR = 'MMM yyyy',
    DATE_SHORT_FRIENDLY = 'M/d/yy', // Added this format
}

export const formatDate = (date: Date, dateFormat: string | DateFormat): string => {
    return format(date, dateFormat);
};

/**
 * Get a relative date string or a formatted date based on the difference from the current date.
 * @param {Date | string} date - the date to format
 * @return {string}
 */
export function getRelativeOrFullDate(date: Date | string): string {
    const currentDate = new Date();
    const targetDate = toValidDate(date);

    if (!targetDate) {
        logger.warn(`Invalid date provided: ${date}`);
        return 'Invalid Date';
    }

    const daysDifference = differenceInDays(currentDate, targetDate);

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === 1) {
        return 'Yesterday';
    } else if (daysDifference < 7) {
        return `${daysDifference} days ago`;
    } else {
        return formatDate(targetDate, DateFormat.DATE_SHORT_FRIENDLY);
    }
}
