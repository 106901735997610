import useAuth from '@hooks/useAuth';
import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import OrganizationTrial from '@models/OrganizationTrial';
import { isDefined } from '@util/TypeGuards';

const useOrganizationTrial = () => {
    const { user } = useAuth();
    const organization = user?.organization;
    const organizationId = organization?.uuid;
    const isTrial = organization?.is_trial ?? false;
    const hasBillingID = organization?.has_billing_id ?? false;
    const trialExpiresAt = organization?.trial_expires_at;
    const response = useSWR<OrganizationTrial>(() =>
        isDefined(organizationId) && isTrial ? Endpoints.organization.trial({ organizationId }) : null,
    );

    const data = response.data;

    return {
        ...response,
        data,
        loading: !data && !response.error && isTrial,
        isTrial: isDefined(data) || isTrial,
        hasBillingID: hasBillingID,
        trialExpiresAt: trialExpiresAt,
    };
};

export default useOrganizationTrial;
