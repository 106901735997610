import { useContext, useEffect } from 'react';
import { QueryParam } from '@services/QueryParams';
import { useRouter } from 'next/router';
import { AuthContext, SeverityColor } from '@contexts/AuthContext';
import { blankToNull, blankToUndefined, isNotBlank } from '@util/StringUtil';

type QueryParamType = string | undefined | null;

/**
 * Watch for the query params on the router to change and take various actions as a result.
 */
const useWatchAuthQueryParams = () => {
    const router = useRouter();
    const authContext = useContext(AuthContext);
    useEffect(() => {
        const continueUrl = router.query[QueryParam.CONTINUE_URL] as QueryParamType;
        const message = router.query[QueryParam.MESSAGE] as QueryParamType;
        const skipProfileSetup = router.query[QueryParam.CONTINUE_SKIP_SETUP] === 'true';
        const severity = blankToUndefined(router.query[QueryParam.MESSAGE_LEVEL] as QueryParamType) as
            | SeverityColor
            | undefined;

        if (isNotBlank(continueUrl)) {
            authContext.setContinueParams({
                continueUrl: continueUrl,
                skipProfileSetup: skipProfileSetup,
            });
        }
        if (message && isNotBlank(message)) {
            authContext.setMessage({ message: blankToNull(message), severity });
        }
    }, [router.query]);
};

export default useWatchAuthQueryParams;
