import { isBlank, isNotBlank } from '@util/StringUtil';
import { isDefined } from '@util/TypeGuards';
import { GenericCellData } from '@models/ExperimentData';
import * as Yup from 'yup';

export const VoidFunction = () => undefined;
export type BasicSetter<T> = (value: T) => void;

export type ArrowData<T extends GenericCellData, KEYS extends keyof T = keyof T> = {
    [K in KEYS]: T[K][];
};

export type FormSchema<Fields> = Partial<{
    [Key in keyof Fields]: Yup.AnySchema<Fields[Key] | null | undefined | unknown>;
}>;

export const toggleValue = <T extends string | number>(values: T[], variable: T): T[] => {
    let updated = [...values];
    if (!updated.includes(variable)) {
        updated.push(variable);
    } else {
        updated = updated.filter((v) => v !== variable);
    }
    return updated;
};

export function allEnumNumericValues<T extends number>(type: Record<string, number | string>): T[] {
    const list: T[] = [];
    for (const l in type) {
        const value = Number(l);
        if (!isNaN(value)) {
            list.push(value as T);
        }
    }
    return list;
}

export function allEnumStringValues<T extends string>(type: Record<string, string | number>): T[] {
    const list: T[] = [];
    for (const l in type) {
        list.push(l as T);
    }
    return list;
}

export function proseListJoiner(
    items: (string | null | undefined)[],
    options?: { listDelimiter?: string; lastDelimiter?: string },
): string {
    const { listDelimiter = ', ', lastDelimiter = ' and ' } = options ?? {};
    const _items = items.filter(isNotBlank);

    if (_items.length === 0) {
        return '';
    }

    if (_items.length === 1) {
        return _items[0] ?? '';
    }

    const listItems = _items.slice(0, Math.max(_items.length - 1, 0));
    const lastItem = _items[_items.length - 1];
    return [listItems.join(listDelimiter), lastItem].filter(isNotBlank).join(lastDelimiter);
}

export function pluralize(count: number, singular: string, plural: string): string {
    if (count === 1) return singular;
    return plural;
}

export const removeBlankKeys = <T extends Record<string, unknown> = Record<string, unknown>>(input: T): T => {
    const params = { ...input };
    Object.keys(params).forEach((key) => {
        const value = params[key];

        if (isBlank(`${value}`) || !isDefined(value)) {
            delete params[key];
        }
    });
    return params;
};

export const removeUndefinedValues = <T extends Record<string, unknown> = Record<string, unknown>>(input: T): T => {
    const params = { ...input };
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value === undefined) {
            delete params[key];
        }
    });
    return params;
};

/**
 * Return a single value or null from a parameter that is either a value, an array, or not defined. If the value is an array, the first item in the array is returned
 * @param {T[] | T | null | undefined} value
 * @return {T | null}
 */
export const valueOrFirst = <T>(value?: T | T[] | null | undefined): T | null => {
    if (Array.isArray(value)) {
        return value[0] ?? null;
    }
    return value ?? null;
};

export type TimeoutValue = ReturnType<typeof setTimeout>;
export type IntervalType = ReturnType<typeof setInterval>;

/**
 * Determine if a list has items. Empty lists or null/undefined values will return false
 * @param {unknown[] | null} list
 * @return {boolean}
 */
export const hasItems = <T>(list?: T[] | null): list is T[] => {
    if (!list) {
        return false;
    }

    if (!Array.isArray(list)) {
        return false;
    }
    return list.length > 0;
};

/**
 * If a list is empty or undefined
 * @param {unknown[] | null} list
 * @return {boolean}
 */
export const isEmptyList = (list?: unknown[] | null): boolean => {
    return !hasItems(list);
};

/**
 * Recursively compare if two arrays have the same elements. Returns true if they are equal
 * @param {T extends Record<string, any>} a
 * @param {T extends Record<string, any>} b
 * @return {boolean}
 */
export const equalsCheck = <T extends Record<string, any>>(a: T, b: T): boolean => {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every((k) => equalsCheck(a[k], b[k]));
};

/**
 * Deep compare 2 objects and return string array of changed values
 * @param {T extends Record<string, any>} values
 * @param {T extends Record<string, any>} initialValues
 * @return {Partial<T>}
 */
export const getChangedValues = <T extends Record<string, any>>(values: T, initialValues: T): Partial<T> => {
    return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
        const hasChanged = !equalsCheck(initialValues[key as keyof T], value);
        if (hasChanged) {
            acc[key as keyof T] = value;
        }

        return acc;
    }, {});
};

/**
 * Deep compare 2 objects. Returns true if deep equals
 * @param {T extends Record<string, any> | null} object1
 * @param {T extends Record<string, any> | null} object2
 * @return {boolean}
 */
export const deepEqual = <T extends Record<string, any> | null>(object1: T, object2: T): boolean => {
    const keys1 = Object.keys(object1 ?? {});
    const keys2 = Object.keys(object2 ?? {});

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1 && object1[key];
        const val2 = object2 && object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
};

/**
 * Determine if value is an object
 * @param {T extends string | any} value
 * @return {boolean}
 */
export const isObject = <T extends string | any>(value: T): boolean => {
    return value != null && typeof value === 'object';
};

/**
 * Returns true if the given value is unique in the array.
 *
 * @param value - The value to check for uniqueness.
 * @param index - The index of the value in the array.
 * @param array - The array being checked.
 * @returns A boolean indicating whether the value is unique.
 */
export const onlyUnique = <T>(value: T, index: number, array: T[]): boolean => {
    return array.indexOf(value) === index;
};

/**
 * Retrieves the first key of an object.
 *
 * @param obj - The object to retrieve the first key from.
 * @returns The first key of the object, or undefined if the object is empty.
 */
export const getFirstKey = <T extends Record<string, any>>(obj: T): keyof T | undefined => {
    for (const key in obj) {
        return key; // Return the first key encountered
    }
    return undefined; // Return undefined if the object is empty
};

/**
 * Checks if an array has duplicates of a target string.
 * @param arr - The array to check for duplicates.
 * @param targetStr - The target string to check for duplicates.
 * @param allowedNumber - The allowed number of duplicates (default is 1).
 * @returns A boolean indicating whether the array has the specified number of duplicates of the target string.
 */
export const hasDuplicates = (arr: string[], targetStr: string, allowedNumber = 1): boolean => {
    return arr.filter((str) => str.toLowerCase() === targetStr.toLowerCase()).length >= allowedNumber;
};

/**
 * Checks if an array of objects has duplicates for a given key.
 * @param arr - The array of objects to check for duplicates.
 * @param key - The key to check for duplicates.
 * @returns True if duplicates are found, false otherwise.
 */
export const hasDuplicatesForKey = <T extends Record<string, any>>(arr: T[], key: keyof T): boolean => {
    const valueCounts: Record<string, boolean> = {};
    for (const obj of arr) {
        if (!(key in obj)) {
            return false;
        }
        if (valueCounts[String(obj[key])]) {
            return true;
        }
        valueCounts[String(obj[key])] = true;
    }
    return false;
};
