import { CircularProgress, CircularProgressProps } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';

type Props = CircularProgressProps & {
    children?: ReactNode;
    className?: string;
    immediate?: boolean;
    inline?: boolean;
    message?: string;
    noPadding?: boolean;
    textClassName?: string;
    timeout?: number;
};
const LoadingMessage = ({
    children,
    className,
    color = 'primary',
    immediate = false,
    inline = false,
    message,
    noPadding = false,
    size = 20,
    textClassName,
    timeout = 200,
    variant = 'indeterminate',
}: Props) => {
    const [show, setShow] = useState(immediate);

    useEffect(() => {
        const handler = setTimeout(() => {
            setShow(true);
        }, timeout);
        return () => {
            clearTimeout(handler);
        };
    });

    if (!show) {
        return null;
    }

    return (
        <div
            className={cn(
                'flex items-center',
                {
                    'flex-col justify-center space-y-4': !inline,
                    'space-x-2': inline,
                    'p-8': !noPadding && !inline,
                },
                className,
            )}
        >
            <CircularProgress variant={variant} color={color} size={size} />
            {message && <p className={textClassName}>{message}</p>}
            {children}
        </div>
    );
};

export default LoadingMessage;
