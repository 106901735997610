import { Switch } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTrialContext } from '@contexts/TrialContext';
import TextInput from '@components/forms/TextInput';
import Button from '@components/Button';

const TrialDevTools = () => {
    const trialContext = useTrialContext();
    return (
        <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:space-x-4">
                <div>
                    <label>
                        <Switch
                            disabled={trialContext.showBanner && !trialContext.forceShowBanner}
                            checked={trialContext.forceShowBanner}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                trialContext.setForceShowBanner(e.target.checked);
                                if (e.target.checked && trialContext.forceHideBanner) {
                                    trialContext.setForceHideBanner(false);
                                }
                            }}
                        />
                        <span>Force show trial banner</span>
                    </label>
                </div>
                <div>
                    <label>
                        <Switch
                            checked={trialContext.forceHideBanner}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                trialContext.setForceHideBanner(e.target.checked);
                                if (e.target.checked && trialContext.forceShowBanner) {
                                    trialContext.setForceShowBanner(false);
                                }
                            }}
                        />
                        <span>Force hide trial banner</span>
                    </label>
                </div>
            </div>
            <div>
                <TextInput
                    disableFormik
                    type="datetime-local"
                    onChange={(e) => trialContext.setTrialExpiresAtOverride(e.target.value)}
                    value={trialContext.trialExpiresAtOverride ?? ''}
                    label="Trial ends at override"
                    name={'trial_ends_at_override'}
                    noMargin
                />
                {trialContext.trialExpiresAtOverride && (
                    <Button size="small" onClick={() => trialContext.setTrialExpiresAtOverride(null)} color="primary">
                        Clear
                    </Button>
                )}
            </div>
        </div>
    );
};

export default TrialDevTools;
