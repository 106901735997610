import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import cn from 'classnames';

type Setter<T> = Dispatch<SetStateAction<T>>;
const voidSetter: Setter<unknown> = () => undefined;

type ContextType = { dark: boolean; setDark: Setter<boolean> };
const defaultContext: ContextType = { dark: false, setDark: voidSetter };
const ThemeContext = createContext<ContextType>(defaultContext);
ThemeContext.displayName = 'ThemeContext';
export const useTheme = () => {
    const theme = useContext(ThemeContext);

    return { theme };
};

export const ThemeContextProvider = ({
    children,
    ...props
}: { children: ReactNode | undefined } & Partial<Pick<ContextType, 'dark'>>) => {
    const [dark, setDark] = useState(props.dark ?? false);

    return (
        <ThemeContext.Provider value={{ dark, setDark }}>
            <div className={cn('theme', { dark })}>{children}</div>
        </ThemeContext.Provider>
    );
};
