import {
    DEFAULT_THEME_COLOR,
    SummaryHeatmapColorScale,
    ComparativeHeatmapColorScale,
    ThemeColor,
    ThemeStyle,
} from '@models/PlotConfigs';
import { isDefined } from '@util/TypeGuards';
import PaletteColors, { PaletteColor } from '@components/PaletteColors';

export type PlotPalette = {
    colors: PaletteColor[];
    display: PaletteColor[];
    volcanoPlotLines: [PaletteColor, PaletteColor];
};

/**
 * A generic Color value that has properties for a variety of situations.
 * Note: the full class names are important so that tailwind will include them in the bundle at build time,
 * rather than building up the class names dynamically. [Learn more here](https://tailwindcss.com/docs/content-configuration#dynamic-class-names).
 */

const mediumOpacityClass = 'text-opacity-75';
export const getSvgRectClasses = ({ color, style }: { color: PaletteColor; style: ThemeStyle }): string => {
    switch (style) {
        case ThemeStyle.outline:
            return `${color.text} stroke-current stroke-3 fill-white`;
        case ThemeStyle.medium:
            return `${color.text} fill-current ${mediumOpacityClass}`;
        case ThemeStyle.dark:
            return `${color.text} fill-current`;
    }
};

/**
 * Configuration for Bar Colors by ThemeColor.
 * @type {{'[ThemeColor.cool]': PaletteColor[], '[ThemeColor.warm]': PaletteColor[], '[ThemeColor.gray]': PaletteColor[], '[ThemeColor.batlow]': PaletteColor[], '[ThemeColor.roma]': PaletteColor[]}}
 */
export const themeColorMap = {
    [ThemeColor.cool]: [
        PaletteColors.indigo500,
        PaletteColors.indigo300,
        PaletteColors.purple600,
        PaletteColors.purple400,
        PaletteColors.sky400,
        PaletteColors.sky700,
        PaletteColors.violet500,
        PaletteColors.violet300,
        PaletteColors.indigo600,
        PaletteColors.indigo400,
        PaletteColors.purple500,
        PaletteColors.purple300,
        PaletteColors.sky600,
        PaletteColors.sky300,
        PaletteColors.violet600,
        PaletteColors.violet400,
    ],
    [ThemeColor.warm]: [
        PaletteColors.cyan400,
        PaletteColors.teal500,
        PaletteColors.amber300,
        PaletteColors.orange500,
        PaletteColors.red500,
        PaletteColors.cyan500,
        PaletteColors.amber400,
        PaletteColors.teal600,
        PaletteColors.orange600,
        PaletteColors.cyan300,
        PaletteColors.teal400,
        PaletteColors.amber200,
        PaletteColors.orange400,
        PaletteColors.red400,
        PaletteColors.teal300,
        PaletteColors.amber500,
        PaletteColors.cyan600,
        PaletteColors.orange700,
    ],
    [ThemeColor.gray]: [
        PaletteColors.gray800,
        PaletteColors.gray400,
        PaletteColors.gray200,
        PaletteColors.gray600,
        PaletteColors.gray300,
    ],
    [ThemeColor.batlow]: [
        PaletteColors.indigo900,
        PaletteColors.red300,
        PaletteColors.sky900,
        PaletteColors.cyan700,
        PaletteColors.teal700,
        PaletteColors.emerald600,
        PaletteColors.lime600,
        PaletteColors.lime500,
        PaletteColors.amber500,
        PaletteColors.orange300,
        PaletteColors.rose200,
        PaletteColors.fuchsia200,
    ],
    [ThemeColor.roma]: [
        PaletteColors.red900,
        PaletteColors.sky600,
        PaletteColors.amber700,
        PaletteColors.yellow600,
        PaletteColors.yellow300,
        PaletteColors.yellow100,
        PaletteColors.teal100,
        PaletteColors.sky300,
        PaletteColors.sky500,
        PaletteColors.sky700,
        PaletteColors.blue800,
    ],
};
/**
 * Display colors - used for showing a preview of the color palette in the UI.
 */
export const displayThemeColors: { [key in ThemeColor]: PaletteColor[] } = {
    [ThemeColor.cool]: [
        PaletteColors.indigo300,
        PaletteColors.indigo500,
        PaletteColors.purple600,
        PaletteColors.purple400,
        PaletteColors.sky300,
        PaletteColors.sky500,
    ],
    [ThemeColor.warm]: [
        PaletteColors.cyan700,
        PaletteColors.teal500,
        PaletteColors.cyan400,
        PaletteColors.amber300,
        PaletteColors.orange500,
        PaletteColors.red500,
    ],
    [ThemeColor.gray]: [
        PaletteColors.gray700,
        PaletteColors.gray600,
        PaletteColors.gray500,
        PaletteColors.gray400,
        PaletteColors.gray300,
        PaletteColors.gray200,
    ],
    [ThemeColor.batlow]: [
        PaletteColors.indigo900,
        PaletteColors.cyan700,
        PaletteColors.emerald600,
        PaletteColors.orange300,
        PaletteColors.red300,
        PaletteColors.fuchsia200,
    ],
    [ThemeColor.roma]: [
        PaletteColors.red900,
        PaletteColors.yellow600,
        PaletteColors.yellow100,
        PaletteColors.teal100,
        PaletteColors.sky600,
        PaletteColors.blue800,
    ],
};

export const volcanoPlotLineThemeColorMap: { [key in ThemeColor]: [PaletteColor, PaletteColor] } = {
    [ThemeColor.cool]: [PaletteColors.purple900, PaletteColors.sky600],
    [ThemeColor.warm]: [PaletteColors.amber700, PaletteColors.orange900],
    [ThemeColor.gray]: [PaletteColors.amber700, PaletteColors.sky600],
    [ThemeColor.batlow]: [PaletteColors.indigo900, PaletteColors.fuchsia200],
    [ThemeColor.roma]: [PaletteColors.red900, PaletteColors.blue800],
};

export const getComparativeHeatmapColorScale = (
    heatmapColorScale?: ComparativeHeatmapColorScale | null,
): [PaletteColor, PaletteColor, PaletteColor, PaletteColor, PaletteColor] => {
    const color: ComparativeHeatmapColorScale = heatmapColorScale ?? ComparativeHeatmapColorScale.PURPLE_BLACK_GREEN;

    switch (color) {
        case ComparativeHeatmapColorScale.YELLOW_BLACK_BLUE:
            return [
                PaletteColors.yellow500,
                PaletteColors.yellow800,
                PaletteColors.black,
                PaletteColors.blue800,
                PaletteColors.blue500,
            ];
        case ComparativeHeatmapColorScale.PURPLE_BLACK_GREEN:
            return [
                PaletteColors.purple500,
                PaletteColors.purple800,
                PaletteColors.black,
                PaletteColors.green800,
                PaletteColors.green500,
            ];
        case ComparativeHeatmapColorScale.BLUE_WHITE_RED:
        default:
            return [
                PaletteColors.blue800,
                PaletteColors.blue400,
                PaletteColors.white,
                PaletteColors.red400,
                PaletteColors.red800,
            ];
    }
};

export const getSummaryHeatmapColorScale = (
    heatmapColorScale?: SummaryHeatmapColorScale | null,
): [PaletteColor, PaletteColor, PaletteColor, PaletteColor, PaletteColor] => {
    const color: SummaryHeatmapColorScale = heatmapColorScale ?? SummaryHeatmapColorScale.VIRIDIS;

    switch (color) {
        case SummaryHeatmapColorScale.GRAY_SCALE:
            return [
                PaletteColors.gray200,
                PaletteColors.gray400,
                PaletteColors.gray600,
                PaletteColors.gray800,
                PaletteColors.black,
            ];
        case SummaryHeatmapColorScale.VIRIDIS:
            return [
                PaletteColors.viridisYellow,
                PaletteColors.viridisLime,
                PaletteColors.viridisGreen,
                PaletteColors.viridisBlue,
                PaletteColors.viridisPurple,
            ];
        case SummaryHeatmapColorScale.PLUTO_PURPLE:
        default:
            return [
                PaletteColors.plutoPurple200,
                PaletteColors.plutoPurple400,
                PaletteColors.plutoPurple600,
                PaletteColors.plutoPurple800,
                PaletteColors.plutoPurple,
            ];
    }
};

export const getPlotPalette = (color: ThemeColor | null = DEFAULT_THEME_COLOR): PlotPalette => {
    // use a bit of a hacky workaround to catch some situations where `color` is passed in as a string by mistake
    const themeColor = !isDefined(color) || isNaN(color as number) ? ThemeColor.cool : color;
    const colors = themeColorMap[themeColor];
    const displayColors = displayThemeColors[themeColor];
    const volcanoPlotLines = volcanoPlotLineThemeColorMap[themeColor];

    return { display: displayColors, colors, volcanoPlotLines };
};
