import { allEnumNumericValues } from '@util/ObjectUtil';
import { ChoiceItem } from '@components/experiments/plotDisplay/fields/SimpleSelectField';

export type ScaleValue = 'linear' | 'symlog';

export enum ThemeColor {
    cool,
    warm,
    gray,
    batlow,
    roma,
}

export enum ComparativeHeatmapColorScale {
    BLUE_WHITE_RED,
    YELLOW_BLACK_BLUE,
    PURPLE_BLACK_GREEN,
}

export enum SummaryHeatmapColorScale {
    PLUTO_PURPLE,
    GRAY_SCALE,
    VIRIDIS,
}

export const DEFAULT_THEME_COLOR = ThemeColor.cool;

export type HeatmapTypes = 'summary' | 'comparative';
export const allThemeColors: ThemeColor[] = allEnumNumericValues(ThemeColor);
export const allComparativeHeatmapColorScales: ComparativeHeatmapColorScale[] =
    allEnumNumericValues(ComparativeHeatmapColorScale);
export const allSummaryHeatmapColorScales: SummaryHeatmapColorScale[] = allEnumNumericValues(SummaryHeatmapColorScale);

export enum ThemeStyle {
    outline,
    medium,
    dark,
}

export const allThemeStyles: ThemeStyle[] = allEnumNumericValues(ThemeStyle);

export interface PlotTheme {
    theme_color: ThemeColor;
    theme_style?: ThemeStyle;
}

export interface GenericPlotOptions extends PlotTheme {
    plot_title: string | null;
    show_p_value?: boolean;
    show_nes_value?: boolean;
}

export interface YAxisConfig extends YAxisRangeConfig {
    y_axis_title: string | null;
    y_axis_scale?: ScaleValue | null;
}

export interface YAxisRangeConfig {
    y_axis_start: number | null;
    y_axis_end: number | null;
}

export interface XAxisConfig extends XAxisRangeConfig {
    x_axis_title: string | null;
}

export interface XAxisRangeConfig {
    x_axis_start: number | null;
    x_axis_end: number | null;
}

export interface FoldChangeThresholdsConfig {
    fold_change_fill_threshold_lower: number;
    fold_change_fill_threshold_upper: number;
}

export interface ShowFoldChangeConfig {
    show_fold_change_lines: boolean;
}

export interface PValueConfig {
    pval_fill_threshold: number | null;
}

export interface PlotTypeConfig {
    plot_type: string;
}

export interface PlotsToShowConfig {
    plots_to_show: string;
}

export interface PlotStatisticConfig {
    plot_statistic: string;
}

export interface PeakSetConfig {
    peak_set: string;
}

export interface LegendLocationConfig {
    legend_location: string;
}

export interface ShowPValueConfig {
    show_pval_line: boolean;
}

export interface RefPointLabelConfig {
    ref_point_label: boolean;
}

export enum PointShape {
    triangle,
    square,
}

export enum PointColor {
    red,
    green,
    blue,
    teal,
}

export interface PlotPointsConfig {
    color_points_by: number[];
    shape_points_by: number[];
}

export interface ErrorBarsConfig {
    show_error_bars: boolean;
    error_bars_locations: ErrorBarLocation[] | null;
    error_bars_option?: ErrorBarOption | null;
}

export interface ShowDataPointsConfig {
    show_data_points: boolean;
}

export interface BarTypeConfig {
    stacked?: boolean;
}

export type ErrorBarLocation = 'top' | 'bottom';
export type ErrorBarOption = 'sd' | 'sem';

export interface BarPlotOptions
    extends GenericPlotOptions,
        YAxisConfig,
        PlotPointsConfig,
        ErrorBarsConfig,
        ShowDataPointsConfig,
        BarTypeConfig {}

export interface BoxPlotOptions extends GenericPlotOptions, YAxisConfig, PlotPointsConfig, ShowDataPointsConfig {}

export interface VolcanoPlotOptions
    extends GenericPlotOptions,
        ShowFoldChangeConfig,
        FoldChangeThresholdsConfig,
        ShowPValueConfig,
        PValueConfig {}

export const AXIS_LABEL_CLASSNAMES = 'text-default text-sm font-sans';
export const AXIS_LABEL_PUBLICATION_CLASSNAMES = 'text-black font-semibold text-[14px] font-sans';
export const AXIS_TITLE_CLASSNAMES = 'text-default text-lg font-semibold font-sans';
export const AXIS_TITLE_PUBLICATION_CLASSNAMES = 'text-[16px] text-black font-semibold font-sans';
export const GENE_SET_INFO_CLASSNAMES = 'text-default text-[16px] font-semibold font-sans';

export const LegendLocationSelectOptions: ChoiceItem[] = [
    {
        value: 'best',
        label: 'Best',
    },
    {
        value: 'upper-right',
        label: 'Upper-right',
    },
    {
        value: 'upper-left',
        label: 'Upper-left',
    },
    {
        value: 'upper-center',
        label: 'Upper-center',
    },
    {
        value: 'lower-left',
        label: 'Lower-left',
    },
    {
        value: 'lower-right',
        label: 'Lower-right',
    },
    {
        value: 'lower-center',
        label: 'Lower-center',
    },
    {
        value: 'center',
        label: 'Center',
    },
    {
        value: 'center-left',
        label: 'Center-left',
    },
    {
        value: 'center-right',
        label: 'Center-right',
    },
];

export const PlotStatisticSelectOptions: ChoiceItem[] = [
    {
        value: 'mean',
        label: 'Mean',
    },
    {
        value: 'median',
        label: 'Median',
    },
    {
        value: 'min',
        label: 'Min',
    },
    {
        value: 'max',
        label: 'Max',
    },
    {
        value: 'std',
        label: 'Standard deviation',
    },
    {
        value: 'sum',
        label: 'Sum',
    },
];

export const PlotsToShowSelectOptions: ChoiceItem[] = [
    {
        value: 'phc',
        label: 'Plot, heatmap and colorbar',
    },
    {
        value: 'ph',
        label: 'Plot and heatmap',
    },
    {
        value: 'h',
        label: 'Heatmap only',
    },
    {
        value: 'hc',
        label: 'Heatmap and colorbar',
    },
];

export const PlotTypeSelectOptions: ChoiceItem[] = [
    {
        value: 'lines',
        label: 'Lines',
    },
    {
        value: 'fill',
        label: 'Fill',
    },
    {
        value: 'se',
        label: 'Standard error',
    },
    {
        value: 'std',
        label: 'Standard deviation',
    },
];
