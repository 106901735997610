import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { XIcon } from '@heroicons/react/outline';

type Props = { onClose: () => void; onDark?: boolean; tooltext?: string };
const DialogCloseButton = ({ onClose, onDark, tooltext }: Props) => {
    return (
        <span className="DialogCloseButton absolute right-2 top-2 z-10" data-role="close">
            <IconButton
                sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                onClick={() => onClose()}
                color="primary"
                size="large"
            >
                {tooltext ? (
                    <Tooltip title={tooltext} arrow placement="left">
                        <XIcon width={24} className={onDark ? 'text-white' : ''} />
                    </Tooltip>
                ) : (
                    <XIcon width={24} className={onDark ? 'text-white' : ''} />
                )}
            </IconButton>
        </span>
    );
};

export default DialogCloseButton;
