import { ApiResponse } from '@api/ApiTypes';
import { User } from '@models/User';

export interface LoginParams {
    email: string;
    password: string;
}

export interface RegistrationParams {
    email: string;
    password1: string;
    password2: string;
    terms_accepted: boolean;
}

export interface AuthSuccessResponse {
    user: User;
    access_token: string;
    access_token_expiration?: string | null;
    refresh_token: string;
    refresh_token_expiration?: string | null;
}

export interface LoginSuccessResponse extends AuthSuccessResponse {}

type FieldError = { message: string; code: string };

export interface LoginErrorResponse {
    message: string;
    code: string;
    details: {
        email?: FieldError[];
        password?: FieldError[];
        non_field_errors: FieldError[];
    };
}

export interface RegistrationResponse extends AuthSuccessResponse {}

export interface RegistrationErrorResponse {
    message: string;
    code: string;
    details: {
        email?: FieldError[];
        password1?: FieldError[];
        password2?: FieldError[];
    };
}

export interface LogoutApiResponse {
    detail: string;
}

export const MAX_TOKEN_REFRESH_DELAY = 7 * 24 * 60 * 60 * 1000; // 7 days
export interface RefreshTokenResponse {
    access_token?: string;
    refresh_token?: string;
    access_token_expiration?: string;
    refresh_token_expiration?: string;
}

export interface ForgotPasswordResponse {
    detail: string;
}

export interface ResetPasswordSuccess {
    detail: string;
}

export type ResetPasswordParams = { uid: string; token: string; new_password1: string; new_password2: string };

export type ResetPasswordResponse = ApiResponse<ResetPasswordSuccess>;

export type VerifyEmailResponse = ApiResponse<{
    success?: boolean;
}>;

export type GoogleSignInParams = { terms_accepted?: boolean } & (
    | {
          access_token: string;
      }
    | { code: string; scope: string }
);

export interface GoogleSignInResponse extends AuthSuccessResponse {}
