import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

const spacing = (multiplier: number) => {
    return 8 * multiplier;
};

const buttonPadding = '0.5rem 1.5rem';
const smallbuttonPadding = '0.25rem .8rem';

const theme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    color: '#616ECF',
                    border: '1px solid #CED4FB',
                    borderRadius: 8,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: 'white',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    margin: '10px',
                    borderRadius: '1rem',
                },
                paperFullWidth: {
                    width: '100%',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingLeft: spacing(4),
                    paddingRight: spacing(4),
                    paddingTop: spacing(4),
                    fontSize: 'inherit',
                    fontWeight: 'unset',
                    fontFamily: 'inherit',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingLeft: spacing(4),
                    paddingRight: spacing(4),
                    paddingBottom: '6px',
                    paddingTop: 0,
                    '&:last-child': {
                        paddingBottom: spacing(4),
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: spacing(4),
                    paddingTop: spacing(2),
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    background: 'white',
                },
                outlined: {
                    borderWidth: 2,
                    padding: '9.5px 8px 9.5px 8px',
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                lineVertical: {
                    minHeight: '13px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: 'transparent',
                    border: '1px solid lightgray',
                    borderRadius: '50%',
                    '&$active': {
                        color: 'transparent',
                        opacity: 1,
                    },
                    '&$completed': {
                        color: 'lightgray',
                        border: 'none',
                        opacity: 0.7,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2,
                    },
                },
                input: {
                    '&:focus': {
                        boxShadow: 'none',
                        border: 'none',
                        outline: 'none',
                    },
                },
                adornedEnd: {
                    paddingRight: '56px',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                endAdornment: {
                    right: '4px',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none',
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '5rem',
                    fontWeight: 600,
                    outlineRadius: '5rem',
                    padding: buttonPadding,
                    textTransform: 'none',
                    '&:focus': {
                        outline: 'none',
                    },
                    transition:
                        'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
                contained: {
                    '&:hover': {
                        color: '#ffffff',
                    },
                },
                containedPrimary: {
                    boxShadow:
                        '0px 3px 1px -2px rgb(99 102 241 / 20%), 0px 2px 2px 0px rgb(99 102 241 / 14%), 0px 1px 5px 0px rgb(99 102 241 / 12%)',
                    '&:hover': {
                        boxShadow:
                            '0px 2px 4px -1px rgb(99 102 241 / 20%), 0px 4px 5px 0px rgb(99 102 241 / 14%), 0px 1px 10px 0px rgb(99 102 241 / 12%)',
                    },
                },
                outlined: {
                    padding: buttonPadding,
                    '&:hover': {
                        color: '#3343AB',
                    },
                },
                containedSizeSmall: {
                    padding: smallbuttonPadding,
                },
                startIcon: {
                    marginRight: 4,
                    opacity: 0.8,
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    marginBottom: 0,
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#3343AB',
            light: '#CED4FB',
        },
        secondary: {
            main: '#616ECF',
        },
        error: {
            main: '#CD3939',
        },
        warning: {
            main: '#D39437',
        },
        info: {
            main: '#3343AB',
        },
        success: {
            main: '#14B8A6',
        },
        background: {
            default: '#F9F9F9',
        },
        text: {
            primary: '#6b7180',
        },
    },
    typography: {
        fontFamily: [
            '"Open Sans"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

const MENU_ITEM_HEIGHT = 48;
const MENU_ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default theme;
