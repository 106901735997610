import { useRouter } from 'next/router';
import { appendQueryParams, QueryParam } from '@services/QueryParams';
import { KeyedMutator, useSWRConfig } from 'swr';
import Logger from '@util/Logger';
import { User } from '@models/User';
import Endpoints from '@services/Endpoints';
import { useContext } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import { isNotBlank } from '@util/StringUtil';
import { LoginParams } from '@api/auth/AuthApi';
import { isDefined } from '@util/TypeGuards';
import { SignupPayload } from '@util/AuthUtil';

const logger = Logger.make('hooks/useAuth');
const useAuth = () => {
    const authContext = useContext(AuthContext);
    const router = useRouter();
    const { mutate } = useSWRConfig();
    const currentPath = router.asPath;
    const { user, loading: authLoading, ready: authReady } = authContext;
    const isLoggedIn = isDefined(user);

    const loginRedirect = async (options?: {
        continueUrl?: string | null;
        useContinueUrl?: boolean;
        skipProfileSetup?: boolean;
        message?: string | null;
        loginPath?: string;
    }) => {
        const {
            useContinueUrl = true,
            continueUrl = currentPath,
            skipProfileSetup = false,
            message,
            loginPath: _loginPath = '/login',
        } = options ?? {};
        let loginPath = _loginPath;
        if (useContinueUrl && continueUrl) {
            authContext.setContinueParams({ continueUrl, skipProfileSetup });
            if (isNotBlank(message)) {
                authContext.setMessage({ message });
            }
            loginPath = appendQueryParams(loginPath, {
                [QueryParam.CONTINUE_URL]: continueUrl,
                [QueryParam.CONTINUE_SKIP_SETUP]: `${skipProfileSetup}`,
                [QueryParam.MESSAGE]: message,
            });
        }

        await router.push(loginPath);
    };

    const revalidateUser: KeyedMutator<User> = async (
        data = (current) => current,
        shouldRevalidate = true,
    ): Promise<User | undefined> => {
        logger.debug('[revalidateUser]', { data, shouldRevalidate });
        return mutate<User>(Endpoints.user.info(), data, shouldRevalidate);
    };

    const submitLogin = async (params: LoginParams) => {
        return authContext.submitLogin(params);
    };

    const submitSignup = async (params: SignupPayload) => {
        return authContext.submitSignup(params);
    };

    /**
     * Copied from auth store
     */
    const loginSuccessRedirect = async (user: User) => {
        if (!user) {
            logger.warn('No user provided in login success redirect.');
            return;
        }
        const continueUrl = authContext.continueParams.continueUrl ?? '/labspace';
        logger.info('[loginSuccessRedirect] using continue url: ', continueUrl);
        const skipProfileSetup = authContext.continueParams.skipProfileSetup ?? false;
        const profileComplete = user.registration_completed ?? false;

        /** set to `true` if the context has been completed, else set to false¬ */
        let clearContextParams;
        if (continueUrl && (skipProfileSetup || profileComplete)) {
            logger.debug('[loginSuccessRedirect] sending user to', continueUrl);
            clearContextParams = true;
            await router.push(continueUrl);
        } else if (!profileComplete) {
            logger.debug(
                '[loginSuccessRedirect] user does not have registration complete, sending to /setup-profile and keeping context params',
            );
            clearContextParams = false;
            await router.push('/setup-profile');
        } else if (continueUrl) {
            logger.debug('[loginSuccessRedirect] sending to continue url', continueUrl);
            clearContextParams = true;
            await router.push(continueUrl);
        } else {
            logger.debug('[loginSuccessRedirect] no continue url, sending to /labspace');
            clearContextParams = true;
            await router.push('/labspace');
        }
        logger.info('[loginSuccessRedirect] Done routing the user - clearing authContext params');
        // The  user is logged in now, so we can remove the context message
        authContext.setMessage(null);
        if (clearContextParams) {
            authContext.setContinueParams({ continueUrl: null, skipProfileSetup: false });
        }

        return;
    };

    return {
        authContext,
        user,
        isLoggedIn,
        authReady,
        loginRedirect,
        authLoading,
        logout: authContext.logout,
        revalidateUser,
        submitLogin,
        submitSignup,
        loginSuccessRedirect,
        isPlutoUser: user?.email?.endsWith('@pluto.bio') ?? false,
    };
};

export default useAuth;
