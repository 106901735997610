export type NullableProps<T> = { [K in keyof T]: T[K] | null };

export function isDefined<T>(input: T | null | undefined): input is T {
    return input !== null && input !== undefined;
}

export type IndexSignatureHack<T> =
    Record<string, unknown> extends T ? { [K in keyof T]: IndexSignatureHack<T[K]> } : T;

export function isEmptyObject<T>(input?: IndexSignatureHack<T> | null): boolean {
    return Object.keys(input ?? {}).length === 0;
}

export function isNonEmptyObject<T>(input?: IndexSignatureHack<T> | null): boolean {
    return Object.keys(input ?? {}).length > 0;
}

export function isString(input: string | unknown): input is string {
    return typeof input === 'string';
}

export function isNumber(input: string | number | unknown): input is number {
    return typeof input === 'number' && Number.isFinite(input);
}

export type RequiredNonNullable<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};
