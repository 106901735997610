import buildFetcher, { ApiUtil } from '@services/ApiFetcher';
import { useContext, useMemo } from 'react';
import ApiService from '@services/ApiService';
import { AuthContext } from '@contexts/AuthContext';

export type RequestBody = unknown;
export type ResponseBody = unknown;
export type QueryParams = Record<string, unknown>;
export const useApi = () => {
    const { getTokens } = useContext(AuthContext);
    const { access_token, refresh_token } = getTokens() ?? {};

    const { apiService, fetcher } = useMemo(() => {
        const fetcher = buildFetcher({ getTokens });
        const apiService = ApiService.withTokens({ getTokens });
        return { apiService, fetcher };
    }, []);

    const { get, put, patch, post, doDelete } = ApiUtil(fetcher);

    return { fetcher, apiService, access_token, refresh_token, get, put, patch, post, doDelete };
};

export default useApi;
