import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import LocalStorageService, { StorageKey } from '@util/LocalStorageService';
import { isDefined } from '@util/TypeGuards';
import { getWindow, isBrowser } from '@util/config';
import { ContextNotInitializedError } from '@contexts/ContextErrors';

export type FeatureName = 'none' | 'benchling_export' | 'plot_size_debug';
type FeatureStatus = 'enabled' | 'disabled';
type FeatureConfig = { status: FeatureStatus };
type FeatureConfigMap = Partial<Record<FeatureName, FeatureConfig>>;
type ContextType = {
    features: FeatureConfigMap;
    setFeature: (name: FeatureName, changes: Partial<FeatureConfig>) => void;
    isEnabled: (name: FeatureName) => boolean;
};

const FeatureToggleContext = createContext<ContextType | null>(null);
const displayName = 'FeatureToggleContext';
FeatureToggleContext.displayName = displayName;

export const useFeatureToggleContext = () => {
    const context = useContext(FeatureToggleContext);
    if (!context) {
        throw new ContextNotInitializedError({
            name: displayName,
        });
    }
    return context;
};

export const FeatureToggleContextProvider = ({ children }: { children: ReactNode }) => {
    const [features, setFeatures] = useState<FeatureConfigMap>({});

    const setFeature = (name: FeatureName, changes: Partial<FeatureConfig>) => {
        setFeatures((current) => {
            return { ...current, [name]: { ...current[name], ...changes } };
        });
    };

    const isEnabled = (name: FeatureName) => {
        return features[name]?.status === 'enabled';
    };

    /** Load in feature from local storage when the provider mounts. Set up storage listener to sync events from other tabs/windows */
    useEffect(() => {
        if (!isBrowser()) {
            return;
        }
        const storageValues = LocalStorageService.getObject<FeatureConfigMap>(StorageKey.FEATURE_TOGGLES);
        if (storageValues) {
            setFeatures((current) => ({
                ...current,
                ...storageValues,
            }));
        }

        const storageHandler = (e: StorageEvent) => {
            // setIsDev(LocalStorageService.isDevMode());
            if (e.key !== StorageKey.FEATURE_TOGGLES) {
                return;
            }
            if (!isDefined(e.newValue)) {
                return;
            }

            const parsed = JSON.parse(e.newValue) as Partial<FeatureConfigMap>;
            setFeatures((current) => ({
                ...current,
                ...parsed,
            }));
        };
        getWindow()?.addEventListener('storage', storageHandler);

        return () => {
            getWindow()?.removeEventListener('storage', storageHandler);
        };
    }, []);

    /** Sync feature with local storage when they change */
    useEffect(() => {
        if (isBrowser()) {
            LocalStorageService.setObject(StorageKey.FEATURE_TOGGLES, features);
        }
    }, [features]);

    return (
        <FeatureToggleContext.Provider
            value={{
                features,
                setFeature,
                isEnabled,
            }}
        >
            {children}
        </FeatureToggleContext.Provider>
    );
};
