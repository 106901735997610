import { MutatorOptions, useSWRConfig } from 'swr';
import { MutatorCallback } from 'swr/dist/types';
import Logger from '@util/Logger';
import { stripQueryString } from '@services/QueryParams';
import { ensureTrailingSlash } from '@services/EndpointUtil';

const logger = Logger.make('hooks/useMatchMutate');
const useMatchMutate = () => {
    const { cache, mutate } = useSWRConfig();

    const typedCache = cache as Map<string, unknown>;
    typedCache.keys();

    const validateCache = () => {
        if (!(cache instanceof Map)) {
            throw new Error('matchMutate requires the cache provider to be a Map instance');
        }
    };

    const doMutations = <Data = unknown>(
        test: (key: string) => boolean,
        data?: Data | Promise<Data> | MutatorCallback<Data>,
        options?: boolean | MutatorOptions,
    ) => {
        validateCache();
        const keys: string[] = [];

        for (const key of typedCache.keys()) {
            if (test(key)) {
                keys.push(key);
            }
        }
        logger.debug('[doMutations] invalidating cache keys', keys);
        // Note: using a spread operator here because doing otherwise was causing the
        // cached items to be removed + re-fetched entirely rather than just re-validate
        const args = [data, options].filter((arg) => arg !== undefined);
        const mutations = keys.map((key) => mutate(key, ...args));
        return Promise.all(mutations);
    };

    const regexMutate = <Data = unknown>(
        matcher: RegExp,
        data?: Data | Promise<Data> | MutatorCallback<Data>,
        options?: boolean | MutatorOptions,
    ) => {
        const test = (cacheKey: string) => matcher.test(cacheKey);
        return doMutations(test, data, options);
    };

    const startsWithMutate = <Data = unknown>(
        matcher: string,
        data?: Data | Promise<Data> | MutatorCallback<Data>,
        options?: boolean | MutatorOptions,
    ) => {
        const test = (cacheKey: string) => cacheKey.startsWith(matcher);
        return doMutations(test, data, options);
    };

    /**
     * Strip out any query params when matching
     * @param {string} matcher
     * @param {Promise<Data> | MutatorCallback<Data> | Data} data
     * @param {boolean|MutatorOptions} options
     * @return {Promise<Awaited<unknown>[]>}
     */
    const pathEqualsIgnoreQueryMutate = <Data = unknown>(
        matcher: string,
        data?: Data | Promise<Data> | MutatorCallback<Data>,
        options?: boolean | MutatorOptions,
    ) => {
        const test = (cacheKey: string) =>
            ensureTrailingSlash(stripQueryString(cacheKey)) === ensureTrailingSlash(stripQueryString(matcher));
        return doMutations(test, data, options);
    };

    const includesMutate = <Data = unknown>(
        matcher: string,
        data?: Data | Promise<Data> | MutatorCallback<Data>,
        options?: boolean | MutatorOptions,
    ) => {
        const test = (cacheKey: string) => cacheKey.includes(matcher);
        return doMutations(test, data, options);
    };

    return { regexMutate, startsWithMutate, includesMutate, pathEqualsIgnoreQueryMutate, mutate };
};

export default useMatchMutate;
