import { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { User } from '@models/User';
import { Organization } from '@models/Organization';
import buildFetcher, { ApiUtil, serializeBody } from '@services/ApiFetcher';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import {
    AuthSuccessResponse,
    LoginParams,
    LoginSuccessResponse,
    LogoutApiResponse,
    MAX_TOKEN_REFRESH_DELAY,
    RefreshTokenResponse,
    RegistrationParams,
} from '@api/auth/AuthApi';
import { TimeoutValue } from '@util/ObjectUtil';
import { isDefined } from '@util/TypeGuards';
import { formatMilliseconds, isBlank, isNotBlank } from '@util/StringUtil';
import { ConnectWithGooglePayload, getDurationUntilAccessTokenRefreshNeeded, SignupPayload } from '@util/AuthUtil';
import LocalStorageService, { StorageKey } from '@util/LocalStorageService';
import AnalyticsService from '@services/AnalyticsService';
import useSWR, { useSWRConfig } from 'swr';
import { ProfileUpdateParams } from '@api/user/UserApiTypes';
import { isApiError } from '@api/ApiTypes';
import { useRouter } from 'next/router';
import { isBrowser } from '@util/config';

const logger = Logger.make('AuthContext', 'auth_status');

export type SeverityColor = 'success' | 'info' | 'warning' | 'error';
const VoidFunction = () => undefined;
let refreshTimeout: TimeoutValue | null = null;
export type TokenInfo = {
    access_token: string | null;
    refresh_token: string | null;
    access_token_expires_at: number | null;
    refresh_token_expires_at: number | null;
    org_header: string | null;
};
type LoginContinueParams = {
    continueUrl: string | null;
    skipProfileSetup: boolean;
};

type LoginMessage = {
    message: string | null;
    severity?: SeverityColor;
    closeable?: boolean;
};

const defaultLoginContinueParams: LoginContinueParams = {
    continueUrl: null,
    skipProfileSetup: false,
};

type ContextType = {
    user: User | null;
    // tokens: TokenInfo | null;
    continueParams: LoginContinueParams;
    setContinueParams: (params: Partial<LoginContinueParams>) => void;
    message: LoginMessage | null;
    setMessage: (message: LoginMessage | null) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    ready: boolean;
    loggedIn: boolean;
    refreshAccessToken: (params?: { refresh_token?: string | null }) => Promise<RefreshTokenResponse | null>;
    refreshTokenIfNeeded: () => Promise<void>;
    submitLogin: (params: LoginParams) => Promise<User | null>;
    submitSignup: (params: SignupPayload) => Promise<User | null>;
    connectWithGoogle: (params: ConnectWithGooglePayload) => Promise<User | null>;
    logout: () => Promise<void>;
    getTokens: () => TokenInfo | null;
    authenticating: boolean;
    userHasMultiOrg: boolean;
    orgSlug: string;
};

const defaultContext: ContextType = {
    user: null,
    // tokens: null,
    continueParams: defaultLoginContinueParams,
    message: null,
    setMessage: VoidFunction,
    setContinueParams: VoidFunction,
    loading: false,
    setLoading: VoidFunction,
    ready: false,
    loggedIn: false,
    refreshAccessToken: () => Promise.resolve(null),
    refreshTokenIfNeeded: () => Promise.resolve(),
    submitLogin: () => Promise.resolve(null),
    submitSignup: () => Promise.resolve(null),
    connectWithGoogle: () => Promise.resolve(null),
    logout: () => Promise.resolve(),
    getTokens: () => null,
    authenticating: false,
    userHasMultiOrg: false,
    orgSlug: '',
};

export const AuthContext = createContext<ContextType>(defaultContext);
AuthContext.displayName = 'AuthContext';
export const AuthContextProvider = ({ children }: { children?: ReactNode }) => {
    const router = useRouter();
    const [orgSlug, setOrgSlug] = useState<string>('');
    const [user, setUser] = useState<User | null>(null);
    const [userHasMultiOrg, setUserHasMultiOrg] = useState<boolean>(false);

    // TODO: clean up the booleans for the app state -- do we need initialized, ready, and loading?
    /**
     * Indicates if the user object is loading
     */
    const [loading, setLoading] = useState(false);

    /**
     * Indicates if the tokens have been initialized
     */
    const [tokensInitialized, setTokensInitialized] = useState(false);

    /**
     * Boolean to indicate if the app is ready to be rendered. Will wait for the user object if access tokens are present
     */
    const [ready, setReady] = useState(false);

    /**
     * The user's access and refresh tokens, if present
     */
    // const [tokens, setTokens] = useState<TokenInfo | null>(null);
    const tokenRef = useRef<TokenInfo | null>(null);
    const [authenticating, setAuthenticating] = useState(false);
    /**
     * Params for how to direct a user upon a successful login/registration/profile setup
     */
    const [loginContinueParams, _setLoginContinueParams] = useState<LoginContinueParams>(defaultLoginContinueParams);

    /**
     * Contextual message to show on the login/signup forms
     */
    const [message, setMessage] = useState<LoginMessage | null>(null);

    const setLoginContinueParams = (params: Partial<LoginContinueParams>) => {
        _setLoginContinueParams((current) => ({ ...current, ...params }));
    };

    const swrConfig = useSWRConfig();

    const {
        data: userData,
        error: userError,
        mutate: mutateUser,
    } = useSWR<User>(
        // When logging in, we do not expect or need the organization header
        () =>
            !!tokenRef.current?.access_token &&
            (!!tokenRef.current?.org_header ||
                router.pathname.includes('accept-invite') ||
                router.pathname.includes('login'))
                ? Endpoints.user.info()
                : null,
        {
            fetcher: buildFetcher({ getTokens: () => tokenRef.current }),
            revalidateOnFocus: false,
            revalidateOnReconnect: true,
        },
    );
    const userInsideAuthFlow =
        router.pathname.includes('/login') ||
        router.pathname.includes('/signup') ||
        router.pathname.includes('/accept-invite') ||
        router.pathname.includes('/forgot-password') ||
        router.pathname.includes('/reset-password') ||
        router.pathname.includes('/verify-email') ||
        router.pathname === '/experiments/[plutoId]/plots/[plotId]';
    const userLoading = isDefined(tokenRef.current?.access_token) && !user && !userError;
    const fetcher = useMemo(() => {
        return buildFetcher({ getTokens: () => tokenRef.current });
    }, []);

    const refreshAccessToken = async ({ refresh_token }: { refresh_token?: string | null } = {}) => {
        setLoading(true);
        let refreshResponse: RefreshTokenResponse | null = null;
        try {
            refreshResponse = await fetcher<RefreshTokenResponse>(Endpoints.auth.token.refresh(), {
                method: 'POST',
                body: refresh_token ? JSON.stringify({ refresh_token: refresh_token }) : undefined,
            });
            const access_token_expires_at = refreshResponse.access_token_expiration
                ? new Date(refreshResponse.access_token_expiration).getTime()
                : null;
            const refresh_token_expires_at = refreshResponse.refresh_token_expiration
                ? new Date(refreshResponse.refresh_token_expiration).getTime()
                : null;

            const tokens: TokenInfo = {
                access_token: refreshResponse.access_token ?? null,
                refresh_token: refreshResponse.refresh_token ?? null,
                access_token_expires_at,
                refresh_token_expires_at,
                org_header: tokenRef.current?.org_header ?? null,
            };
            tokenRef.current = tokens;

            if (isBrowser()) {
                try {
                    const sessionResponse = await fetch('/api/sessions', {
                        method: 'POST',
                        body: serializeBody(refreshResponse),
                        headers: { 'Content-Type': 'application/json' },
                    });
                    logger.info('FE session response', sessionResponse);
                } catch (error) {
                    logger.error('failed to post to the front-end session endpoint');
                }
            }

            const refreshDelay = getDurationUntilAccessTokenRefreshNeeded(access_token_expires_at);
            if (isDefined(refreshDelay)) {
                await setupAccessTokenRefreshTask(refreshDelay);
            }
        } catch (error) {
            if (isApiError(error)) {
                if (error.status !== 400) {
                    logger.error(error);
                } else {
                    logger.warn(error);
                }
            }
            if (!userInsideAuthFlow) router.replace('/login');
        } finally {
            setLoading(false);
        }
        return refreshResponse;
    };

    const setupAccessTokenRefreshTask = async (durationMs: number) => {
        if (!isBrowser()) {
            return;
        }
        if (isDefined(refreshTimeout)) {
            logger.debug('[refresh task] Removing existing timeout', refreshTimeout);
            clearTimeout(refreshTimeout);
        }
        logger.debug(`[refresh task] next refresh is due in: ${formatMilliseconds(durationMs)}`);
        if (durationMs <= 0) {
            logger.info('[refresh task] next refresh interval is past due, trying to refresh now');
            await refreshAccessToken();
        } else {
            logger.debug(
                `[refresh task] Creating refresh task. setting timeout for ${formatMilliseconds(
                    durationMs,
                )} from now at ${new Date(durationMs + Date.now()).toLocaleString()}`,
            );

            refreshTimeout = setTimeout(
                async () => {
                    logger.debug(
                        '[refresh task] Running scheduled refresh task - attempting refreshing access token now',
                    );
                    await refreshAccessToken();
                },
                Math.min(MAX_TOKEN_REFRESH_DELAY, durationMs),
            );
        }
    };

    const refreshTokenIfNeeded = async () => {
        if (!tokenRef.current?.refresh_token || !tokenRef.current) {
            return;
        }

        const refreshDelay = getDurationUntilAccessTokenRefreshNeeded(tokenRef.current?.access_token_expires_at);
        if (!isDefined(refreshDelay)) {
            logger.debug('[refreshTokenIfNeeded] no refresh delay found, not attempting a refresh');
            return;
        }
        await setupAccessTokenRefreshTask(refreshDelay);
    };

    const submitLogin = async (params: LoginParams): Promise<User | null> => {
        setAuthenticating(true);
        const loginResult = await fetcher<LoginSuccessResponse>(Endpoints.auth.login(), {
            method: 'POST',
            body: JSON.stringify({ ...params, email: params.email.toLowerCase().trim() }),
        });
        await refreshAccessToken({ refresh_token: loginResult.refresh_token });
        setAuthenticating(false);
        return loginResult.user;
    };

    const submitSignup = async (params: SignupPayload): Promise<User | null> => {
        setAuthenticating(true);
        const signupResult = await ApiUtil(fetcher).post<LoginSuccessResponse, RegistrationParams>(
            Endpoints.auth.registration(),
            {
                email: params.email?.toLowerCase().trim(),
                password1: params.password,
                password2: params.password,
                terms_accepted: params.terms_accepted,
            },
        );
        await refreshAccessToken({ refresh_token: signupResult.refresh_token });
        setAuthenticating(false);
        return signupResult.user;
    };

    /**
     * Log out a user and send them to a specified page.
     * If no destination specified, the homepage will be used.
     * @param {string} destination
     * @return {Promise<void>}
     */
    const logout = async (destination = '/') => {
        logger.info('logging out of the app');
        setLoading(true);

        setUser(null);
        try {
            await router.push({ pathname: destination, query: undefined });
            const typedCache = swrConfig.cache as Map<string, unknown>;
            if (typedCache.clear) {
                typedCache.clear();
            } else {
                logger.warn('SWR Cache does not have method "clear"');
            }
            await Promise.all([
                // Log out from the API
                fetcher<LogoutApiResponse>(Endpoints.auth.logout(), { method: 'POST' }),

                // Log out from the FE server
                fetch('/api/sessions', {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                }),
            ]);

            setMessage(null);
            // setTokens(null);
            tokenRef.current = null;
            setLoginContinueParams(defaultLoginContinueParams);
            LocalStorageService.removeItem(StorageKey.AUTH_USER_ID);
            AnalyticsService.signOut();
            if (isDefined(refreshTimeout)) {
                clearInterval(refreshTimeout);
            }
        } catch (error) {
            logger.error(error);
        }

        setLoading(false);
    };

    /**
     * Connect a Google sign-in with the API.
     * @throws Error when something goes wrong connecting with the API.
     * @param {ConnectWithGooglePayload} params
     * @return {Promise<User | null>}
     */
    const connectWithGoogle = async (params: ConnectWithGooglePayload): Promise<User | null> => {
        logger.debug('connecting with google', params);
        setAuthenticating(true);
        const endpoint = params.isLogIn
            ? Endpoints.auth.social.googleLogin()
            : Endpoints.auth.social.googleRegistration();
        const response = await fetcher<AuthSuccessResponse>(endpoint, {
            method: 'POST',
            body: JSON.stringify({ ...params, terms_accepted: true }),
        });
        logger.info('[connectWithGoogle] connect google with api user', response);
        const { access_token, refresh_token, user } = response;
        await refreshAccessToken({ refresh_token });
        logger.info('[connectWithGoogle] tokens refreshed');

        if (user && isBlank(user.avatar_url) && isNotBlank(params.avatar_url)) {
            logger.info('[connectWithGoogle] updating user profile avatar info');
            const fetcher = buildFetcher({ access_token });
            const profileParams: ProfileUpdateParams = { avatar_url: params.avatar_url };

            try {
                const updatedUser = await fetcher<User>(Endpoints.user.profile(), {
                    method: 'PUT',
                    body: JSON.stringify(profileParams),
                });
                logger.info('[connectWithGoogle] updated profile response', updatedUser);
                if (updatedUser) {
                    await mutateUser((current) => {
                        if (!current) {
                            return updatedUser;
                        }

                        return { ...current, avatar_url: updatedUser.avatar_url };
                    }, true);
                }
            } catch (error) {
                logger.error('[connectWithGoogle]', error);
            }
        }
        setAuthenticating(false);
        return response.user;
    };

    const initialize = async () => {
        try {
            setTokensInitialized(false);
            const refreshResult = await refreshAccessToken();
            if (!refreshResult?.access_token) {
                logger.info('[initialize] user is not logged in');
            }
        } catch (error) {
            logger.error(error);
        } finally {
            setTokensInitialized(true);
        }
    };

    useEffect(() => {
        if (!userData) return;
        setUser(userData);
    }, [userData]);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        setTokensInitialized(false);
        if (!!router.query.organization && (!orgSlug || orgSlug !== (router.query.organization as string)))
            setOrgSlug(router.query.organization as string);
    }, [router.query.organization]);

    useEffect(() => {
        if (!tokenRef.current?.access_token) return;

        const updateUser = async () => {
            try {
                const updatedUser = await fetcher<User>(Endpoints.user.info());
                logger.info('[update user org] new org header', updatedUser);

                if (updatedUser) setUser(updatedUser);
                setTokensInitialized(true);
            } catch (error) {
                logger.error('[update user org]', error);
            }
        };

        const setOrganizationHeader = async () => {
            setUserHasMultiOrg(false);
            const orgRequestHeaders = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokenRef.current?.access_token}`,
            };
            const orgHeaderResponse = await fetcher<Organization[]>(Endpoints.organizations(), {
                method: 'GET',
                headers: tokenRef.current?.access_token ? orgRequestHeaders : undefined,
            });

            if (!orgHeaderResponse) return;

            // Handle Users with no Organization
            if (!orgHeaderResponse.length && !!tokenRef.current) {
                tokenRef.current.org_header = '';
                return await updateUser();
            }

            // Set Org header for everyone
            const activeOrg =
                orgHeaderResponse.find((org: Organization) => org.org_slug === orgSlug) ?? orgHeaderResponse[0];
            if (!!activeOrg && !!tokenRef.current) {
                tokenRef.current.org_header = activeOrg.uuid;
                await updateUser();
            }

            //Set org slug for users with multiple orgs
            if (orgHeaderResponse.length > 1) {
                setUserHasMultiOrg(true);
                if (!orgSlug && !!activeOrg.org_slug) setOrgSlug(activeOrg.org_slug);
            }
        };

        setOrganizationHeader();
    }, [orgSlug, tokenRef.current?.access_token]);

    useEffect(() => {
        if (user) {
            LocalStorageService.setItem(StorageKey.AUTH_USER_ID, user.uuid);
        }
        if (ready) {
            return;
        }
        if (user || (!userLoading && tokensInitialized)) {
            setReady(true);
        }
    }, [user, userError, ready, loading, tokensInitialized]);

    const getTokens = () => {
        return tokenRef.current;
    };

    return (
        <AuthContext.Provider
            value={{
                user: user ?? null,
                continueParams: loginContinueParams,
                setContinueParams: setLoginContinueParams,
                message,
                setMessage,
                loading,
                setLoading,
                ready,
                refreshAccessToken,
                refreshTokenIfNeeded,
                submitLogin,
                submitSignup,
                logout,
                connectWithGoogle,
                loggedIn: isDefined(user),
                getTokens,
                authenticating,
                userHasMultiOrg,
                orgSlug,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

type MockProps = { children: ReactNode; user?: User | null } & Partial<ContextType>;
export const MockAuthContextProvider = ({ children, user, ...props }: MockProps) => {
    return (
        <AuthContext.Provider
            value={{
                user: user ?? null,
                continueParams: props.continueParams ?? defaultLoginContinueParams,
                setContinueParams: props.setContinueParams ?? (() => undefined),
                message: props.message ?? null,
                setMessage: props.setMessage ?? (() => undefined),
                loading: props.loading ?? false,
                setLoading: props.setLoading ?? (() => undefined),
                ready: props.ready ?? true,
                refreshAccessToken: props.refreshAccessToken ?? (() => Promise.resolve(null)),
                refreshTokenIfNeeded: props.refreshTokenIfNeeded ?? (async () => undefined),
                submitLogin: props.submitLogin ?? (async () => null),
                submitSignup: props.submitSignup ?? (async () => null),
                logout: props.logout ?? (async () => undefined),
                connectWithGoogle: props.connectWithGoogle ?? (async () => null),
                loggedIn: isDefined(user),
                getTokens: props.getTokens ?? (() => null),
                authenticating: props.authenticating ?? false,
                userHasMultiOrg: props.userHasMultiOrg ?? false,
                orgSlug: props.orgSlug ?? '',
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
