import { defaultProps, IconProps } from '@components/icons/CustomIcons';

export const SendIcon = (props?: IconProps) => {
    const { height, width } = { ...defaultProps, ...props };
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
        </svg>
    );
};
