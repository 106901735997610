import { useTheme } from '@contexts/ThemeContext';
import { ChangeEvent } from 'react';
import { Switch } from '@mui/material';

const ThemeSettings = () => {
    const { theme } = useTheme();

    return (
        <section>
            <div>
                <label>
                    <Switch
                        checked={theme.dark}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => theme.setDark(e.target.checked)}
                    />
                    <span>Dark mode</span>
                </label>
            </div>
        </section>
    );
};

export default ThemeSettings;
