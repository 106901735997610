import { useSearchParam } from 'react-use';
import { useEffect } from 'react';
import { isBrowser } from '@util/config';
import { removeQueryParamFromPath } from '@services/QueryParams';
import { useRouter } from 'next/router';
import Logger from '@util/Logger';

const logger = Logger.make('useWatchDevToolsQueryParam');

const useWatchDevToolsQueryParam = () => {
    const param = useSearchParam('devtools');
    const router = useRouter();
    useEffect(() => {
        if (param?.toLowerCase() === 'on' && isBrowser()) {
            logger.debug('enabling DevTools via query params');

            window?.PlutoDev?.on();
            const updatedPath = removeQueryParamFromPath(router.asPath, 'devtools');
            router.replace(updatedPath);
        }
    }, [param]);

    return;
};

export default useWatchDevToolsQueryParam;
