import { GoogleSignInParams, RefreshTokenResponse } from '@api/auth/AuthApi';
import { User } from '@models/User';
import { GetServerSidePropsContext } from 'next';
import Logger from '@util/Logger';
import Endpoints from '@services/Endpoints';
import buildFetcher, { ApiUtil } from '@services/ApiFetcher';
import { CookieName } from '@util/CookieService';

const logger = Logger.make('AuthUtil');

export const TOKEN_EXPIRATION_REFRESH_BUFFER_MS = 120000;

export interface LoginPayload {
    email: string;
    password: string;
}

export type ConnectWithGooglePayload = GoogleSignInParams & {
    isLogIn: boolean;
    terms_accepted?: boolean;
    avatar_url?: string;
};

export interface SignupPayload extends LoginPayload {
    terms_accepted: boolean;
}

export interface AuthTokenPayload {
    access_token: string;
    refresh_token: string;
    access_token_expiration_ms?: number | null;
    refresh_token_expiration_ms?: number | null;
}

/**
 * Determine the duration, from the current time, until the access token needs to be refreshed.
 * @param {number | null} expiresAt
 * @param {number} bufferMs
 * @return {number | null}
 */
export const getDurationUntilAccessTokenRefreshNeeded = (
    expiresAt: number | null | undefined,
    bufferMs: number = TOKEN_EXPIRATION_REFRESH_BUFFER_MS,
): number | null => {
    if (!expiresAt) {
        return null;
    }
    const bufferedExpiration = expiresAt - bufferMs;

    return bufferedExpiration - Date.now();
};

/**
 * Server-side method to get auth tokens via a refresh token
 * @param {string | null} refresh_token
 * @return {Promise<RefreshTokenResponse | null>}
 */
const fetchAccessToken = async ({
    refresh_token,
}: {
    refresh_token?: string | null;
}): Promise<RefreshTokenResponse | null> => {
    if (!refresh_token) {
        logger.info('no refresh token present, not fetching the access token');
        return null;
    }
    try {
        const fetcher = buildFetcher({ refresh_token });
        const response = await ApiUtil(fetcher).post<RefreshTokenResponse>(
            Endpoints.auth.token.refresh(),
            { refresh_token },
            {
                credentials: 'include',
            },
        );
        return response;
    } catch (error) {
        logger.error(error);
    }
    return null;
};

/**
 * Fetch the user on the server for server side rendering
 */
export const getServerSession = async (
    context: GetServerSidePropsContext,
    options?: { disableAuth?: boolean },
): Promise<{
    session: RefreshTokenResponse | null;
    api: ReturnType<typeof ApiUtil>;
    getUser: () => Promise<User | null>;
}> => {
    const { disableAuth } = options ?? {};
    const cookies = context.req.cookies;
    const refresh_token = cookies[CookieName.REFRESH_TOKEN_FE];
    const session = disableAuth ? null : await fetchAccessToken({ refresh_token });
    const access_token: string | null = session?.access_token ?? null;
    const fetcher = buildFetcher(session);

    const getUser = async (): Promise<User | null> => {
        if (!access_token || disableAuth) {
            return null;
        }
        try {
            const user = await ApiUtil(fetcher).get<User>(Endpoints.user.info());
            return user ?? null;
        } catch (error) {
            logger.error(error);
        }
        return null;
    };

    return { api: ApiUtil(fetcher), session, getUser };
};
