import { getFullName, User } from '@models/User';
import { isBrowser } from '@util/config';
import Logger from '@util/Logger';
import * as Sentry from '@sentry/react'; // Import Sentry

const logger = Logger.make('AnalyticsService');

/* eslint-disable */
declare global {
    interface Window {
        hsConversationsOnReady: (() => void)[];
        _hsq: any[];
        HubSpotConversations?: IHubSpotConversations;
        hsConversationsSettings?: {
            loadImmediately: boolean;
            identificationEmail: string;
            identificationToken: string;
        };
    }

    interface IHubSpotConversations {
        widget: {
            load: () => void;
            remove: () => void;
        };
    }
}
/* eslint-enable */

export interface AnalyticsWindow extends Window {
    dataLayer?: unknown[];
}

export default {
    identifyUser: (user?: User | null) => {
        if (isBrowser() && user && user.uuid) {
            const fullName = getFullName(user);

            // Add the Hubspot and Sentry tracking
            if (window) {
                //Sentry.captureMessage('User performed a specific action', 'info');
                Sentry.setUser({
                    id: user.uuid,
                    email: user.email,
                    username: fullName,
                    organization: user.organization?.name,
                });

                const _hsq = (window._hsq = window._hsq || []);
                _hsq.push([
                    'identify',
                    {
                        email: user.email,
                        id: user.uuid,
                        fullName: fullName,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        company: user.organization?.name ?? '',
                    },
                ]);

                fetch('/api/hubspot', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: user.email, firstName: user.first_name, lastName: user.last_name }),
                })
                    .then((res) => res.json())
                    .then((token) => {
                        if (window.HubSpotConversations) {
                            // HubSpot Conversations settings
                            window.hsConversationsSettings = {
                                loadImmediately: false,
                                identificationEmail: user.email,
                                identificationToken: token, // Use the fetched token here
                            };
                        }
                    });
            }
        }
    },
    signOut: () => {
        Sentry.setUser(null); // Clear Sentry user
    },
};

interface PageEventProps {
    event: string;
    page: string;
}

export const logPageView = (url: string) => {
    const pageEvent: PageEventProps = {
        event: 'pageview',
        page: url,
    };

    if (isBrowser()) {
        logger.debug('logging page view for url', url);
        (window as AnalyticsWindow).dataLayer?.push(pageEvent);
    }

    return pageEvent;
};

export type RegistrationMethod = 'email' | 'google';

interface RegistrationEventProps {
    event: string;
    authenticationMethod: RegistrationMethod;
    userId: string;
}

export const fireRegistrationEvent = (user: User | null | undefined, method: RegistrationMethod) => {
    if (!isBrowser()) {
        return;
    }
    if (!user?.uuid) {
        logger.warn('Can not fire registration event. No userID was found on the provided user.', user);
        return;
    }

    const pageEvent: RegistrationEventProps = {
        event: 'registration',
        authenticationMethod: method,
        userId: user.uuid,
    };
    logger.debug(`Firing GA "${method}" registration event for user`, user.email);
    (window as AnalyticsWindow).dataLayer?.push(pageEvent);
};

export const fireDataLayerEvent = (
    payload: Record<string, string | number | boolean | null | undefined>,
    context?: { user?: User | null | undefined; path?: string; pathname?: string },
) => {
    if (!isBrowser()) {
        return;
    }

    const event: Record<string, string | number | boolean | undefined | null> = {
        ...payload,
    };
    logger.debug(`Firing GTM event`, {
        event,
        context,
    });
    (window as AnalyticsWindow).dataLayer?.push(event);
};
