import Button from '@components/Button';
import React, { useContext } from 'react';
import Logger from '@util/Logger';
import { AuthContext } from '@contexts/AuthContext';

const logger = Logger.make('AuthTokenTools');

const AuthTokenTools = () => {
    const authContext = useContext(AuthContext);

    const refreshToken = async () => {
        try {
            const refreshResult = await authContext.refreshAccessToken();
            logger.log('refresh token result', refreshResult);
            const contextTokens = authContext.getTokens();
            logger.info('returned tokens', contextTokens);
        } catch (error) {
            logger.error(error);
        }
    };

    return (
        <section>
            <div className="space-y-2">
                <div className="flex space-x-2">
                    <Button variant="contained" color="primary" size="small" onClick={() => refreshToken()}>
                        Refresh Auth Tokens
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default AuthTokenTools;
