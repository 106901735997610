import { createContext, ReactNode, useContext, useState } from 'react';
import useAuth from '@hooks/useAuth';
import { Dialog, DialogContent } from '@mui/material';
import { StateSetter } from '@contexts/ContextTypes';
import TrialTasksView from '@components/trial/TrialTasksView';
import { differenceInDays, intervalToDuration, Duration } from 'date-fns';
import useOrganizationTrial from '@hooks/useOrganizationTrial';
import { isDefined } from '@util/TypeGuards';
import DialogCloseButton from '@components/DialogCloseButton';

type ContextType = {
    showBanner: boolean;
    tasksOpen: boolean;
    setTasksOpen: StateSetter<boolean>;
    forceShowBanner: boolean;
    setForceShowBanner: StateSetter<boolean>;
    forceHideBanner: boolean;
    setForceHideBanner: StateSetter<boolean>;

    trialExpiresAtOverride: string | null;
    setTrialExpiresAtOverride: StateSetter<string | null>;

    remainingTrialDuration: Duration | null;
    daysRemaining: number | null;
    trialActive: boolean;
    trialExpiresAt: string | null;
    trialExpired: boolean;
};
const TrialContext = createContext<ContextType | null>(null);

export const useTrialContext = () => {
    const context = useContext(TrialContext);
    if (!context) {
        throw new Error('Trial context has not been initialized');
    }
    return context;
};

export type TrialContextProviderProps = { children: ReactNode };
export const TrialContextProvider = ({ children }: TrialContextProviderProps) => {
    const { user } = useAuth();
    const [tasksOpen, setTasksOpen] = useState(false);
    const [trialExpiresAtOverride, setTrialExpiresAtOverride] = useState<string | null>(null);
    const [forceShowBanner, setForceShowBanner] = useState(false);
    const [forceHideBanner, setForceHideBanner] = useState(false);
    const trialExpiresAt =
        trialExpiresAtOverride && forceShowBanner
            ? trialExpiresAtOverride
            : user?.organization?.trial_expires_at ?? null;
    // Prefetch trial details
    useOrganizationTrial();
    const showBanner = ((user?.organization?.is_trial ?? false) || forceShowBanner) && !forceHideBanner;

    const currentTime = Date.now();
    const endTime = trialExpiresAt ? new Date(trialExpiresAt).getTime() : null;

    const remainingTrialDuration = endTime ? intervalToDuration({ start: currentTime, end: endTime }) : null;
    const trialActive = isDefined(endTime) ? endTime > currentTime : false;

    const trialExpired = user?.organization?.is_trial === true && !trialActive;
    const daysRemaining = trialExpiresAt ? differenceInDays(new Date(trialExpiresAt), new Date()) : null;

    return (
        <TrialContext.Provider
            value={{
                showBanner,
                tasksOpen,
                setTasksOpen,
                forceShowBanner,
                setForceShowBanner,
                forceHideBanner,
                setForceHideBanner,
                remainingTrialDuration,
                trialActive,
                trialExpiresAt,
                trialExpired,
                daysRemaining,
                trialExpiresAtOverride,
                setTrialExpiresAtOverride,
            }}
        >
            <>
                {children}
                <Dialog open={tasksOpen} onClose={() => setTasksOpen(false)} maxWidth="sm" fullWidth>
                    <DialogCloseButton onClose={() => setTasksOpen(false)} />
                    <DialogContent className="">
                        <div className="md:p-4">
                            <TrialTasksView />
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        </TrialContext.Provider>
    );
};
