import { Switch } from '@mui/material';
import { ChangeEvent } from 'react';
import { FeatureName, useFeatureToggleContext } from '@contexts/FeatureToggleContext';

const FeatureToggleSettings = () => {
    const { setFeature, isEnabled } = useFeatureToggleContext();
    const visibleFeatures: { featureName: FeatureName; displayName: string }[] = [
        {
            featureName: 'benchling_export',
            displayName: 'Benchling integration',
        },
        {
            featureName: 'plot_size_debug',
            displayName: 'Debug plot sizing',
        },
    ];
    return (
        <div>
            <table>
                <tbody>
                    {visibleFeatures.map(({ featureName, displayName }) => (
                        <tr key={featureName}>
                            <td>{displayName}</td>
                            <td>
                                <Switch
                                    checked={isEnabled(featureName)}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setFeature(featureName, {
                                            status: e.target.checked ? 'enabled' : 'disabled',
                                        })
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                    {visibleFeatures.length === 0 && (
                        <tr className="font-semibold">
                            <td>No feature toggles enabled currently</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default FeatureToggleSettings;
