export class ErrorBase<T extends string> extends Error implements ErrorWithCause<T> {
    name: T;
    message: string;
    cause: unknown;

    constructor(error: ErrorWithCause<T>) {
        super();
        this.name = error.name;
        this.cause = error.cause;
        this.message = error.message;
    }
}

export interface ErrorWithCause<T> {
    name: T;
    message: string;
    cause: unknown;
}
