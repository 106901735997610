import React from 'react';
import { SVGProps } from 'react';
import cn from 'classnames';

type Props = { maxWidth?: number; centered?: boolean; className?: string };

export const LogoThick = React.forwardRef<SVGSVGElement, SVGProps<SVGSVGElement> & { dark?: boolean }>(
    ({ dark = false, width = 161, height = 93, ...props }, ref) => {
        const letterColor = dark ? 'white' : '#333333';

        const outerColor = dark ? '#6f7fe7' : '#3343AB';
        const innerColor = dark ? '#ced4fb' : '#6F7FE7';
        return (
            <svg
                ref={ref}
                viewBox="0 0 161 93"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                height={height}
                width={width}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.79766 0.632792C13.8326 -1.44524 20.4096 1.76256 22.4876 7.79764C23.6721 11.2375 23.1392 14.8536 21.3442 17.7122L21.3371 17.7269C19.5838 21.4656 19.2264 25.8636 20.6769 30.0763C22.7932 36.2224 28.185 40.3103 34.2285 41.06C43.6611 42.3605 52.0373 48.7974 55.3496 58.4166C60.0395 72.0377 52.7997 86.8817 39.1787 91.5718C25.5577 96.2619 10.7137 89.0219 6.02356 75.401C2.67227 65.6681 5.41213 55.3109 12.2188 48.4798C12.2109 48.4767 12.2026 48.4725 12.1944 48.4685C16.1762 44.1597 17.7211 37.8567 15.6775 31.9215C14.2075 27.6521 11.2082 24.3278 7.48272 22.4491L7.53476 22.3939C4.40622 21.2294 1.8044 18.7254 0.632809 15.3228C-1.44527 9.28778 1.76256 2.71083 7.79766 0.632792Z"
                    fill={outerColor}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.0408 27.4259C48.4333 28.7282 50.1255 32.5395 48.8207 35.9389C48.0768 37.8767 46.5195 39.2623 44.6894 39.8544C42.552 40.8456 40.7817 42.6388 39.8697 45.0146C38.5408 48.4764 39.394 52.2384 41.7541 54.7799C45.3901 58.8035 46.684 64.6855 44.6041 70.1037C41.6589 77.776 33.0646 81.613 25.408 78.6739C17.7514 75.7348 13.9319 67.1326 16.877 59.4603C18.9814 53.9781 23.9702 50.4542 29.4402 49.9613C29.438 49.957 29.4358 49.9523 29.4338 49.9473C32.7481 49.5224 35.7309 47.333 37.0141 43.9898C37.9372 41.5852 37.8474 39.0334 36.9115 36.8475L36.9544 36.8424C36.017 35.1874 35.7999 33.1397 36.5356 31.2231C37.8406 27.8238 41.6485 26.1237 45.0408 27.4259Z"
                    fill={innerColor}
                />
                <path
                    d="M81.4768 36.6863C83.2305 36.6863 84.8248 37.138 86.2596 38.0414C87.6945 38.9183 88.8105 40.1539 89.6076 41.7482C90.4314 43.3159 90.8432 45.0829 90.8432 47.0492C90.8432 49.042 90.4314 50.8356 89.6076 52.4299C88.8105 53.9976 87.6945 55.2332 86.2596 56.1366C84.8514 57.04 83.2704 57.4917 81.5166 57.4917C79.9755 57.4917 78.5539 57.133 77.2519 56.4156C75.9765 55.6716 74.9535 54.6884 74.1829 53.4662V63.9884C74.1829 64.3339 74.0634 64.6129 73.8242 64.8254C73.6116 65.0646 73.3326 65.1842 72.9872 65.1842C72.6418 65.1842 72.3495 65.0646 72.1104 64.8254C71.8978 64.6129 71.7915 64.3339 71.7915 63.9884V38.0016C71.7915 37.6562 71.8978 37.3639 72.1104 37.1247C72.3495 36.8856 72.6418 36.766 72.9872 36.766C73.3326 36.766 73.6116 36.8856 73.8242 37.1247C74.0634 37.3639 74.1829 37.6562 74.1829 38.0016V40.7119C74.8738 39.463 75.8702 38.4799 77.1722 37.7624C78.4742 37.045 79.9091 36.6863 81.4768 36.6863ZM81.2775 55.2597C82.6592 55.2597 83.8948 54.9143 84.9842 54.2234C86.1002 53.506 86.9638 52.5229 87.5749 51.274C88.2126 50.0252 88.5315 48.6169 88.5315 47.0492C88.5315 45.508 88.2126 44.1263 87.5749 42.904C86.9638 41.6552 86.1002 40.6853 84.9842 39.9944C83.8948 39.277 82.6592 38.9183 81.2775 38.9183C79.8692 38.9183 78.6071 39.2637 77.4911 39.9546C76.3751 40.6454 75.5115 41.6153 74.9004 42.8642C74.2892 44.0864 73.9836 45.4814 73.9836 47.0492C73.9836 48.6169 74.2892 50.0252 74.9004 51.274C75.5115 52.5229 76.3751 53.506 77.4911 54.2234C78.6071 54.9143 79.8692 55.2597 81.2775 55.2597Z"
                    fill={letterColor}
                />
                <path
                    d="M98.3986 56.017C98.3986 56.3624 98.2791 56.6547 98.0399 56.8939C97.8274 57.1064 97.5484 57.2127 97.2029 57.2127C96.8575 57.2127 96.5652 57.1064 96.3261 56.8939C96.1135 56.6547 96.0072 56.3624 96.0072 56.017V28.9142C96.0072 28.5687 96.1135 28.2897 96.3261 28.0772C96.5652 27.838 96.8575 27.7184 97.2029 27.7184C97.5484 27.7184 97.8274 27.838 98.0399 28.0772C98.2791 28.2897 98.3986 28.5687 98.3986 28.9142V56.017Z"
                    fill={letterColor}
                />
                <path
                    d="M119.985 36.9653C120.33 36.9653 120.609 37.0849 120.822 37.324C121.061 37.5366 121.18 37.8156 121.18 38.161V49.4007C121.18 52.0047 120.45 54.0109 118.988 55.4192C117.527 56.8009 115.507 57.4917 112.93 57.4917C110.379 57.4917 108.373 56.8009 106.912 55.4192C105.477 54.0109 104.759 52.0047 104.759 49.4007V38.161C104.759 37.8156 104.866 37.5366 105.078 37.324C105.317 37.0849 105.61 36.9653 105.955 36.9653C106.3 36.9653 106.579 37.0849 106.792 37.324C107.031 37.5366 107.151 37.8156 107.151 38.161V49.4007C107.151 51.3139 107.656 52.7753 108.665 53.785C109.675 54.7682 111.097 55.2597 112.93 55.2597C114.79 55.2597 116.225 54.7682 117.235 53.785C118.271 52.7753 118.789 51.3139 118.789 49.4007V38.161C118.789 37.8156 118.895 37.5366 119.108 37.324C119.347 37.0849 119.639 36.9653 119.985 36.9653Z"
                    fill={letterColor}
                />
                <path
                    d="M131.437 39.1574V52.5096C131.437 53.5193 131.623 54.2102 131.995 54.5822C132.367 54.9276 132.859 55.1003 133.47 55.1003C133.629 55.1003 133.829 55.0737 134.068 55.0206C134.307 54.9409 134.493 54.901 134.626 54.901C134.865 54.901 135.064 55.0073 135.224 55.2199C135.41 55.4059 135.503 55.6317 135.503 55.8974C135.503 56.2694 135.29 56.5883 134.865 56.854C134.44 57.0932 133.935 57.2127 133.35 57.2127C132.633 57.2127 131.995 57.1463 131.437 57.0134C130.879 56.8806 130.334 56.482 129.803 55.8177C129.298 55.1534 129.046 54.0906 129.046 52.6292V39.1574H126.136C125.817 39.1574 125.538 39.0512 125.299 38.8386C125.087 38.626 124.98 38.3603 124.98 38.0414C124.98 37.7226 125.087 37.4569 125.299 37.2443C125.538 37.0317 125.817 36.9254 126.136 36.9254H129.046V32.5013C129.046 32.1559 129.152 31.8769 129.365 31.6643C129.604 31.4252 129.896 31.3056 130.241 31.3056C130.587 31.3056 130.866 31.4252 131.078 31.6643C131.318 31.8769 131.437 32.1559 131.437 32.5013V36.9254H135.423C135.715 36.9254 135.968 37.045 136.18 37.2842C136.419 37.5233 136.539 37.789 136.539 38.0813C136.539 38.4002 136.433 38.6659 136.22 38.8784C136.007 39.0644 135.742 39.1574 135.423 39.1574H131.437Z"
                    fill={letterColor}
                />
                <path
                    d="M159.576 47.0093C159.576 49.0022 159.137 50.809 158.26 52.4299C157.383 54.0507 156.174 55.3262 154.633 56.2562C153.092 57.1596 151.378 57.6113 149.492 57.6113C147.605 57.6113 145.891 57.1596 144.35 56.2562C142.809 55.3262 141.6 54.0507 140.723 52.4299C139.846 50.809 139.408 49.0022 139.408 47.0093C139.408 45.0164 139.846 43.2096 140.723 41.5887C141.6 39.9679 142.809 38.6924 144.35 37.7624C145.891 36.8324 147.605 36.3674 149.492 36.3674C151.378 36.3674 153.092 36.8324 154.633 37.7624C156.174 38.6924 157.383 39.9679 158.26 41.5887C159.137 43.2096 159.576 45.0164 159.576 47.0093ZM157.184 47.0093C157.184 45.415 156.852 43.9802 156.188 42.7047C155.523 41.4293 154.593 40.4329 153.398 39.7154C152.229 38.9714 150.927 38.5994 149.492 38.5994C148.057 38.5994 146.755 38.9714 145.586 39.7154C144.417 40.4329 143.487 41.4293 142.796 42.7047C142.131 43.9802 141.799 45.415 141.799 47.0093C141.799 48.577 142.131 49.9986 142.796 51.274C143.487 52.5494 144.417 53.5592 145.586 54.3032C146.755 55.0206 148.057 55.3793 149.492 55.3793C150.927 55.3793 152.229 55.0206 153.398 54.3032C154.593 53.5857 155.523 52.5893 156.188 51.3139C156.852 50.0384 157.184 48.6036 157.184 47.0093Z"
                    fill={letterColor}
                />
                <path
                    d="M81.4768 36.6863C83.2305 36.6863 84.8248 37.138 86.2596 38.0414C87.6945 38.9183 88.8105 40.1539 89.6076 41.7482C90.4314 43.3159 90.8432 45.0829 90.8432 47.0492C90.8432 49.042 90.4314 50.8356 89.6076 52.4299C88.8105 53.9976 87.6945 55.2332 86.2596 56.1366C84.8514 57.04 83.2704 57.4917 81.5166 57.4917C79.9755 57.4917 78.5539 57.133 77.2519 56.4156C75.9765 55.6716 74.9535 54.6884 74.1829 53.4662V63.9884C74.1829 64.3339 74.0634 64.6129 73.8242 64.8254C73.6116 65.0646 73.3326 65.1842 72.9872 65.1842C72.6418 65.1842 72.3495 65.0646 72.1104 64.8254C71.8978 64.6129 71.7915 64.3339 71.7915 63.9884V38.0016C71.7915 37.6562 71.8978 37.3639 72.1104 37.1247C72.3495 36.8856 72.6418 36.766 72.9872 36.766C73.3326 36.766 73.6116 36.8856 73.8242 37.1247C74.0634 37.3639 74.1829 37.6562 74.1829 38.0016V40.7119C74.8738 39.463 75.8702 38.4799 77.1722 37.7624C78.4742 37.045 79.9091 36.6863 81.4768 36.6863ZM81.2775 55.2597C82.6592 55.2597 83.8948 54.9143 84.9842 54.2234C86.1002 53.506 86.9638 52.5229 87.5749 51.274C88.2126 50.0252 88.5315 48.6169 88.5315 47.0492C88.5315 45.508 88.2126 44.1263 87.5749 42.904C86.9638 41.6552 86.1002 40.6853 84.9842 39.9944C83.8948 39.277 82.6592 38.9183 81.2775 38.9183C79.8692 38.9183 78.6071 39.2637 77.4911 39.9546C76.3751 40.6454 75.5115 41.6153 74.9004 42.8642C74.2892 44.0864 73.9836 45.4814 73.9836 47.0492C73.9836 48.6169 74.2892 50.0252 74.9004 51.274C75.5115 52.5229 76.3751 53.506 77.4911 54.2234C78.6071 54.9143 79.8692 55.2597 81.2775 55.2597Z"
                    stroke={letterColor}
                />
                <path
                    d="M98.3986 56.017C98.3986 56.3624 98.2791 56.6547 98.0399 56.8939C97.8274 57.1064 97.5484 57.2127 97.2029 57.2127C96.8575 57.2127 96.5652 57.1064 96.3261 56.8939C96.1135 56.6547 96.0072 56.3624 96.0072 56.017V28.9142C96.0072 28.5687 96.1135 28.2897 96.3261 28.0772C96.5652 27.838 96.8575 27.7184 97.2029 27.7184C97.5484 27.7184 97.8274 27.838 98.0399 28.0772C98.2791 28.2897 98.3986 28.5687 98.3986 28.9142V56.017Z"
                    stroke={letterColor}
                />
                <path
                    d="M119.985 36.9653C120.33 36.9653 120.609 37.0849 120.822 37.324C121.061 37.5366 121.18 37.8156 121.18 38.161V49.4007C121.18 52.0047 120.45 54.0109 118.988 55.4192C117.527 56.8009 115.507 57.4917 112.93 57.4917C110.379 57.4917 108.373 56.8009 106.912 55.4192C105.477 54.0109 104.759 52.0047 104.759 49.4007V38.161C104.759 37.8156 104.866 37.5366 105.078 37.324C105.317 37.0849 105.61 36.9653 105.955 36.9653C106.3 36.9653 106.579 37.0849 106.792 37.324C107.031 37.5366 107.151 37.8156 107.151 38.161V49.4007C107.151 51.3139 107.656 52.7753 108.665 53.785C109.675 54.7682 111.097 55.2597 112.93 55.2597C114.79 55.2597 116.225 54.7682 117.235 53.785C118.271 52.7753 118.789 51.3139 118.789 49.4007V38.161C118.789 37.8156 118.895 37.5366 119.108 37.324C119.347 37.0849 119.639 36.9653 119.985 36.9653Z"
                    stroke={letterColor}
                />
                <path
                    d="M131.437 39.1574V52.5096C131.437 53.5193 131.623 54.2102 131.995 54.5822C132.367 54.9276 132.859 55.1003 133.47 55.1003C133.629 55.1003 133.829 55.0737 134.068 55.0206C134.307 54.9409 134.493 54.901 134.626 54.901C134.865 54.901 135.064 55.0073 135.224 55.2199C135.41 55.4059 135.503 55.6317 135.503 55.8974C135.503 56.2694 135.29 56.5883 134.865 56.854C134.44 57.0932 133.935 57.2127 133.35 57.2127C132.633 57.2127 131.995 57.1463 131.437 57.0134C130.879 56.8806 130.334 56.482 129.803 55.8177C129.298 55.1534 129.046 54.0906 129.046 52.6292V39.1574H126.136C125.817 39.1574 125.538 39.0512 125.299 38.8386C125.087 38.626 124.98 38.3603 124.98 38.0414C124.98 37.7226 125.087 37.4569 125.299 37.2443C125.538 37.0317 125.817 36.9254 126.136 36.9254H129.046V32.5013C129.046 32.1559 129.152 31.8769 129.365 31.6643C129.604 31.4252 129.896 31.3056 130.241 31.3056C130.587 31.3056 130.866 31.4252 131.078 31.6643C131.318 31.8769 131.437 32.1559 131.437 32.5013V36.9254H135.423C135.715 36.9254 135.968 37.045 136.18 37.2842C136.419 37.5233 136.539 37.789 136.539 38.0813C136.539 38.4002 136.433 38.6659 136.22 38.8784C136.007 39.0644 135.742 39.1574 135.423 39.1574H131.437Z"
                    stroke={letterColor}
                />
                <path
                    d="M159.576 47.0093C159.576 49.0022 159.137 50.809 158.26 52.4299C157.383 54.0507 156.174 55.3262 154.633 56.2562C153.092 57.1596 151.378 57.6113 149.492 57.6113C147.605 57.6113 145.891 57.1596 144.35 56.2562C142.809 55.3262 141.6 54.0507 140.723 52.4299C139.846 50.809 139.408 49.0022 139.408 47.0093C139.408 45.0164 139.846 43.2096 140.723 41.5887C141.6 39.9679 142.809 38.6924 144.35 37.7624C145.891 36.8324 147.605 36.3674 149.492 36.3674C151.378 36.3674 153.092 36.8324 154.633 37.7624C156.174 38.6924 157.383 39.9679 158.26 41.5887C159.137 43.2096 159.576 45.0164 159.576 47.0093ZM157.184 47.0093C157.184 45.415 156.852 43.9802 156.188 42.7047C155.523 41.4293 154.593 40.4329 153.398 39.7154C152.229 38.9714 150.927 38.5994 149.492 38.5994C148.057 38.5994 146.755 38.9714 145.586 39.7154C144.417 40.4329 143.487 41.4293 142.796 42.7047C142.131 43.9802 141.799 45.415 141.799 47.0093C141.799 48.577 142.131 49.9986 142.796 51.274C143.487 52.5494 144.417 53.5592 145.586 54.3032C146.755 55.0206 148.057 55.3793 149.492 55.3793C150.927 55.3793 152.229 55.0206 153.398 54.3032C154.593 53.5857 155.523 52.5893 156.188 51.3139C156.852 50.0384 157.184 48.6036 157.184 47.0093Z"
                    stroke={letterColor}
                />
            </svg>
        );
    },
);

const Logo = ({
    centered = true,
    maxWidth = 60,
    className,
    dark = false,
    width,
    height,
}: Props & { dark?: boolean; width?: number; height?: number }) => {
    return (
        <div className={cn('object-contain', className)}>
            <svg
                className={cn({ 'mx-auto': centered })}
                viewBox="0 0 57 93"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ maxWidth: `${maxWidth}px` }}
                width={width}
                height={height}
            >
                <path
                    d="M7.79766 0.632792C13.8326 -1.44524 20.4096 1.76256 22.4876 7.79764C23.6721 11.2375 23.1392 14.8536 21.3442 17.7122L21.3371 17.7269C19.5838 21.4656 19.2264 25.8636 20.6769 30.0763C22.7932 36.2224 28.185 40.3103 34.2285 41.06C43.6611 42.3605 52.0373 48.7974 55.3496 58.4166C60.0395 72.0377 52.7997 86.8817 39.1787 91.5718C25.5577 96.2619 10.7137 89.0219 6.02356 75.401C2.67227 65.6681 5.41213 55.3109 12.2188 48.4798C12.2109 48.4767 12.2026 48.4725 12.1944 48.4685C16.1762 44.1597 17.7211 37.8567 15.6775 31.9215C14.2075 27.6521 11.2082 24.3278 7.48272 22.4491L7.53476 22.3939C4.40622 21.2294 1.8044 18.7254 0.632809 15.3228C-1.44527 9.28778 1.76256 2.71083 7.79766 0.632792Z"
                    fill={dark ? '#3343AB' : '#6F7FE7'}
                />
                <path
                    d="M45.0408 27.4259C48.4333 28.7282 50.1255 32.5395 48.8207 35.9389C48.0768 37.8767 46.5195 39.2623 44.6894 39.8544C42.552 40.8456 40.7817 42.6388 39.8697 45.0146C38.5408 48.4764 39.394 52.2384 41.7541 54.7799C45.3901 58.8035 46.684 64.6855 44.6041 70.1037C41.6589 77.776 33.0646 81.613 25.408 78.6739C17.7514 75.7348 13.9319 67.1326 16.877 59.4603C18.9814 53.9781 23.9702 50.4542 29.4402 49.9613C29.438 49.957 29.4358 49.9523 29.4338 49.9473C32.7481 49.5224 35.7309 47.333 37.0141 43.9898C37.9372 41.5852 37.8474 39.0334 36.9115 36.8475L36.9544 36.8424C36.017 35.1874 35.7999 33.1397 36.5356 31.2231C37.8406 27.8238 41.6485 26.1237 45.0408 27.4259Z"
                    fill={dark ? '#6F7FE7' : '#CED4FB'}
                />
            </svg>
        </div>
    );
};

export default Logo;
