import Colors from 'tailwindcss/colors';

/**
 * Object holding information for painting a specific color in various contexts via css classnames and raw color values (hex, rgb).
 */
export type PaletteColor = {
    /**
     * Class names to configure a background color
     */
    bg: string;

    /**
     * The CSS color value (hex or rgb) value
     */
    color: string;

    /**
     * Class names to set the fill color of an SVG element
     */
    text: string;
};

type EnforceObjectType<T> = <V extends T>(v: V) => V;
const enforceObjectType: EnforceObjectType<{ [key: string]: PaletteColor }> = (v) => v;

const colors = enforceObjectType({
    amber100: {
        bg: 'bg-amber-100',
        color: Colors.amber[100],
        text: 'text-amber-100',
    },
    amber200: {
        bg: 'bg-amber-200',
        color: Colors.amber[200],
        text: 'text-amber-200',
    },
    amber300: {
        bg: 'bg-amber-300',
        color: '#fcd34d',
        text: 'text-amber-300',
    },
    amber400: {
        bg: 'bg-amber-400',
        color: '#fbbf24',
        text: 'text-amber-400',
    },
    amber500: {
        bg: 'bg-amber-500',
        color: '#f59e0b',
        text: 'text-amber-500',
    },
    amber600: {
        bg: 'bg-amber-600',
        color: Colors.amber[600],
        text: 'text-amber-600',
    },
    amber700: {
        bg: 'bg-amber-700',
        color: '#b45309',
        text: 'text-amber-700',
    },
    amber800: {
        bg: 'bg-amber-800',
        color: Colors.amber[800],
        text: 'text-amber-800',
    },
    black: {
        bg: 'bg-black',
        color: Colors.black,
        text: 'text-black',
    },
    blue100: {
        bg: 'bg-blue-100',
        color: Colors.blue[100],
        text: 'text-blue-100',
    },
    blue200: {
        bg: 'bg-blue-200',
        color: Colors.blue[200],
        text: 'text-blue-200',
    },
    blue300: {
        bg: 'bg-blue-300',
        color: Colors.blue[300],
        text: 'text-blue-300',
    },
    blue400: {
        bg: 'bg-blue-400',
        color: Colors.blue[400],
        text: 'text-blue-400',
    },
    blue500: {
        bg: 'bg-blue-500',
        color: Colors.blue[500],
        text: 'text-blue-500',
    },
    blue600: {
        bg: 'bg-blue-600',
        color: Colors.blue[600],
        text: 'text-blue-600',
    },
    blue700: {
        bg: 'bg-blue-700',
        color: Colors.blue[700],
        text: 'text-blue-700',
    },
    blue800: {
        bg: 'bg-blue-800',
        color: Colors.blue[800],
        text: 'text-blue-800',
    },
    blue900: {
        bg: 'bg-blue-900',
        color: Colors.blue[900],
        text: 'text-blue-900',
    },
    cyan100: {
        bg: 'bg-cyan-100',
        color: Colors.cyan[100],
        text: 'text-cyan-100',
    },
    cyan200: {
        bg: 'bg-cyan-200',
        color: Colors.cyan[200],
        text: 'text-cyan-200',
    },
    cyan300: {
        bg: 'bg-cyan-300',
        color: Colors.cyan[300],
        text: 'text-cyan-300',
    },
    cyan400: {
        bg: 'bg-cyan-400',
        color: Colors.cyan[400],
        text: 'text-cyan-400',
    },
    cyan500: {
        bg: 'bg-cyan-500',
        color: Colors.cyan[500],
        text: 'text-cyan-500',
    },
    cyan600: {
        bg: 'bg-cyan-600',
        color: Colors.cyan[600],
        text: 'text-cyan-600',
    },
    cyan700: {
        bg: 'bg-cyan-700',
        color: Colors.cyan[700],
        text: 'text-cyan-700',
    },
    cyan800: {
        bg: 'bg-cyan-800',
        color: Colors.cyan[800],
        text: 'text-cyan-800',
    },
    cyan900: {
        bg: 'bg-cyan-900',
        color: Colors.cyan[900],
        text: 'text-cyan-900',
    },
    emerald100: {
        bg: 'bg-emerald-100',
        color: Colors.emerald[100],
        text: 'text-emerald-100',
    },
    emerald200: {
        bg: 'bg-emerald-200',
        color: Colors.emerald[200],
        text: 'text-emerald-200',
    },
    emerald300: {
        bg: 'bg-emerald-300',
        color: '#86efac',
        text: 'text-emerald-900',
    },
    emerald400: {
        bg: 'bg-emerald-400',
        color: Colors.emerald[400],
        text: 'text-emerald-400',
    },
    emerald500: {
        bg: 'bg-emerald-500',
        color: '#22c55e',
        text: 'text-emerald-900',
    },
    emerald600: {
        bg: 'bg-emerald-600',
        color: Colors.emerald[600],
        text: 'text-emerald-600',
    },
    emerald700: {
        bg: 'bg-emerald-700',
        color: '#15803d',
        text: 'text-emerald-900',
    },
    emerald800: {
        bg: 'bg-emerald-800',
        color: Colors.emerald[800],
        text: 'text-emerald-800',
    },
    emerald900: {
        bg: 'bg-emerald-900',
        color: '#14532d',
        text: 'text-emerald-900',
    },
    fuchsia100: {
        bg: 'bg-fuchsia-100',
        color: Colors.fuchsia[100],
        text: 'text-fuchsia-100',
    },
    fuchsia200: {
        bg: 'bg-fuchsia-200',
        color: Colors.fuchsia[200],
        text: 'text-fuchsia-200',
    },
    fuchsia300: {
        bg: 'bg-fuchsia-300',
        color: '#f0abfc',
        text: 'text-fuchsia-300',
    },
    fuchsia400: {
        bg: 'bg-fuchsia-400',
        color: Colors.fuchsia[400],
        text: 'text-fuchsia-400',
    },
    fuchsia500: {
        bg: 'bg-fuchsia-500',
        color: '#d946ef',
        text: 'text-fuchsia-500',
    },
    fuchsia600: {
        bg: 'bg-fuchsia-600',
        color: Colors.fuchsia[600],
        text: 'text-fuchsia-600',
    },
    fuchsia700: {
        bg: 'bg-fuchsia-700',
        color: '#a21caf',
        text: 'text-fuchsia-700',
    },
    fuchsia800: {
        bg: 'bg-fuchsia-800',
        color: Colors.fuchsia[800],
        text: 'text-fuchsia-800',
    },
    fuchsia900: {
        bg: 'bg-fuchsia-900',
        color: '#701a75',
        text: 'text-fuchsia-900',
    },
    gray50: {
        bg: 'bg-gray-50',
        color: '#f9fafb',
        text: 'text-gray-50',
    },
    gray100: {
        bg: 'bg-gray-100',
        color: '#f3f4f6',
        text: 'text-gray-100',
    },
    gray200: {
        bg: 'bg-gray-200',
        color: '#e5e7eb',
        text: 'text-gray-200',
    },
    gray300: {
        bg: 'bg-gray-300',
        color: '#d1d5db',
        text: 'text-gray-300',
    },
    gray350: {
        bg: 'bg-gray-350',
        color: '#BBBBC1',
        text: 'text-gray-350',
    },
    gray400: {
        bg: 'bg-gray-400',
        color: '#9ca3af',
        text: 'text-gray-400',
    },
    gray500: {
        bg: 'bg-gray-500',
        color: '#6b7280',
        text: 'text-gray-500',
    },
    gray600: {
        bg: 'bg-gray-600',
        color: '#4b5563',
        text: 'text-gray-600',
    },
    gray700: {
        bg: 'bg-gray-700',
        color: '#374151',
        text: 'text-gray-700',
    },
    gray800: {
        bg: 'bg-gray-800',
        color: '#1f2937',
        text: 'text-gray-800',
    },
    gray900: {
        bg: 'bg-gray-900',
        color: '#111827',
        text: 'text-gray-900',
    },
    green100: {
        bg: 'bg-green-100',
        color: Colors.green[100],
        text: 'text-green-100',
    },
    green200: {
        bg: 'bg-green-200',
        color: Colors.green[200],
        text: 'text-green-200',
    },
    green300: {
        bg: 'bg-green-300',
        color: Colors.green[300],
        text: 'text-green-300',
    },
    green400: {
        bg: 'bg-green-400',
        color: Colors.green[400],
        text: 'text-green-400',
    },
    green500: {
        bg: 'bg-green-500',
        color: Colors.green[500],
        text: 'text-green-500',
    },
    green600: {
        bg: 'bg-green-600',
        color: Colors.green[600],
        text: 'text-green-600',
    },
    green700: {
        bg: 'bg-green-700',
        color: Colors.green[700],
        text: 'text-green-700',
    },
    green800: {
        bg: 'bg-green-800',
        color: Colors.green[800],
        text: 'text-green-800',
    },
    green900: {
        bg: 'bg-green-900',
        color: Colors.green[900],
        text: 'text-green-900',
    },
    indigo100: {
        bg: 'bg-indigo-100',
        color: Colors.indigo[100],
        text: 'text-indigo-100',
    },
    indigo200: {
        bg: 'bg-indigo-200',
        color: '#c7d2fe',
        text: 'text-indigo-300',
    },
    indigo300: {
        bg: 'bg-indigo-300',
        color: Colors.indigo[300],
        text: 'text-indigo-300',
    },
    indigo400: {
        bg: 'bg-indigo-400',
        color: '#818cf8',
        text: 'text-indigo-400',
    },
    indigo500: {
        bg: 'bg-indigo-500',
        color: '#6366f1',
        text: 'text-indigo-500',
    },
    indigo600: {
        bg: 'bg-indigo-600',
        color: Colors.indigo[600],
        text: 'text-indigo-600',
    },
    indigo700: {
        bg: 'bg-indigo-700',
        color: '#4338ca',
        text: 'text-indigo-700',
    },
    indigo800: {
        bg: 'bg-indigo-800',
        color: Colors.indigo[800],
        text: 'text-indigo-800',
    },
    indigo900: {
        bg: 'bg-indigo-900',
        color: '#312e81',
        text: 'text-indigo-900',
    },
    lime100: {
        bg: 'bg-lime-100',
        color: Colors.lime[100],
        text: 'text-lime-100',
    },
    lime200: {
        bg: 'bg-lime-200',
        color: Colors.lime[200],
        text: 'text-lime-200',
    },
    lime300: {
        bg: 'bg-lime-300',
        color: Colors.lime[300],
        text: 'text-lime-300',
    },
    lime400: {
        bg: 'bg-lime-400',
        color: Colors.lime[400],
        text: 'text-lime-400',
    },
    lime500: {
        bg: 'bg-lime-500',
        color: Colors.lime[500],
        text: 'text-lime-500',
    },
    lime600: {
        bg: 'bg-lime-600',
        color: Colors.lime[600],
        text: 'text-lime-600',
    },
    lime700: {
        bg: 'bg-lime-700',
        color: Colors.lime[700],
        text: 'text-lime-700',
    },
    lime800: {
        bg: 'bg-lime-800',
        color: Colors.lime[800],
        text: 'text-lime-800',
    },
    lime900: {
        bg: 'bg-lime-900',
        color: Colors.lime[900],
        text: 'text-lime-900',
    },
    orange100: {
        bg: 'bg-orange-100',
        color: Colors.orange[100],
        text: 'text-orange-100',
    },
    orange200: {
        bg: 'bg-orange-200',
        color: '#fed7aa',
        text: 'text-orange-200',
    },
    orange300: {
        bg: 'bg-orange-300',
        color: Colors.orange[300],
        text: 'text-orange-300',
    },
    orange400: {
        bg: 'bg-orange-400',
        color: '#fb923c',
        text: 'text-orange-400',
    },
    orange500: {
        bg: 'bg-orange-500',
        color: Colors.orange[500],
        text: 'text-orange-500',
    },
    orange600: {
        bg: 'bg-orange-600',
        color: '#ea580c',
        text: 'text-orange-600',
    },
    orange700: {
        bg: 'bg-orange-700',
        color: Colors.orange[700],
        text: 'text-orange-700',
    },
    orange800: {
        bg: 'bg-orange-800',
        color: '#9a3412',
        text: 'text-orange-800',
    },
    orange900: {
        bg: 'bg-orange-900',
        color: '#7c2d12',
        text: 'text-orange-900',
    },
    pink100: {
        bg: 'bg-pink-100',
        color: Colors.pink[100],
        text: 'text-pink-100',
    },
    pink200: {
        bg: 'bg-pink-200',
        color: Colors.pink[200],
        text: 'text-pink-200',
    },
    pink300: {
        bg: 'bg-pink-300',
        color: '#f9a8d4',
        text: 'pink-300',
    },
    pink400: {
        bg: 'bg-pink-400',
        color: Colors.pink[400],
        text: 'text-pink-400',
    },
    pink500: {
        bg: 'bg-pink-500',
        color: '#ec4899',
        text: 'pink-500',
    },
    pink600: {
        bg: 'bg-pink-600',
        color: Colors.pink[600],
        text: 'text-pink-600',
    },
    pink700: {
        bg: 'bg-pink-700',
        color: '#be185d',
        text: 'pink-700',
    },
    pink800: {
        bg: 'bg-pink-800',
        color: Colors.pink[800],
        text: 'text-pink-800',
    },
    pink900: {
        bg: 'bg-pink-900',
        color: '#831843',
        text: 'pink-900',
    },
    purple100: {
        bg: 'bg-purple-100',
        color: Colors.purple[100],
        text: 'text-purple-100',
    },
    purple200: {
        bg: 'bg-purple-200',
        color: '#e9d5ff',
        text: 'text-purple-200',
    },
    purple300: {
        bg: 'bg-purple-300',
        color: Colors.purple[300],
        text: 'text-purple-300',
    },
    purple400: {
        bg: 'bg-purple-400',
        color: '#c084fc',
        text: 'text-purple-400',
    },
    purple500: {
        bg: 'bg-purple-500',
        color: Colors.purple[500],
        text: 'text-purple-500',
    },
    purple600: {
        bg: 'bg-purple-600',
        color: Colors.purple[600],
        text: 'text-purple-600',
    },
    purple700: {
        bg: 'bg-purple-700',
        color: '#a21caf',
        text: 'text-purple-700',
    },
    purple800: {
        bg: 'bg-purple-800',
        color: Colors.purple[800],
        text: 'text-purple-800',
    },
    purple900: {
        bg: 'bg-purple-900',
        color: '#581c87',
        text: 'text-purple-900',
    },
    red100: {
        bg: 'bg-red-100',
        color: Colors.red[100],
        text: 'text-red-100',
    },
    red200: {
        bg: 'bg-red-200',
        color: Colors.red[200],
        text: 'text-red-200',
    },
    red300: {
        bg: 'bg-red-300',
        color: Colors.red[300],
        text: 'text-red-300',
    },
    red400: {
        bg: 'bg-red-400',
        color: Colors.red[400],
        text: 'text-red-400',
    },
    red500: {
        bg: 'bg-red-500',
        color: Colors.red[500],
        text: 'text-red-500',
    },
    red600: {
        bg: 'bg-red-600',
        color: Colors.red[600],
        text: 'text-red-600',
    },
    red700: {
        bg: 'bg-red-700',
        color: Colors.red[700],
        text: 'text-red-700',
    },
    red800: {
        bg: 'bg-red-800',
        color: Colors.red[800],
        text: 'text-red-800',
    },
    red900: {
        bg: 'bg-red-900',
        color: Colors.red[900],
        text: 'text-red-900',
    },
    rose100: {
        bg: 'bg-rose-100',
        color: Colors.rose[100],
        text: 'text-rose-100',
    },
    rose200: {
        bg: 'bg-rose-200',
        color: Colors.rose[200],
        text: 'text-rose-200',
    },
    rose300: {
        bg: 'bg-rose-300',
        color: '#fda4af',
        text: 'rose-300',
    },
    rose400: {
        bg: 'bg-rose-400',
        color: Colors.rose[400],
        text: 'text-rose-400',
    },
    rose500: {
        bg: 'bg-rose-500',
        color: '#f43f5e',
        text: 'rose-500',
    },
    rose600: {
        bg: 'bg-rose-600',
        color: Colors.rose[600],
        text: 'text-rose-600',
    },
    rose700: {
        bg: 'bg-rose-700',
        color: '#be123c',
        text: 'rose-700',
    },
    rose800: {
        bg: 'bg-rose-800',
        color: Colors.rose[800],
        text: 'text-rose-800',
    },
    rose900: {
        bg: 'bg-rose-900',
        color: '#881337',
        text: 'rose-900',
    },
    sky100: {
        bg: 'bg-sky-100',
        color: Colors.sky[100],
        text: 'text-sky-100',
    },
    sky200: {
        bg: 'bg-sky-200',
        color: Colors.sky[200],
        text: 'text-sky-200',
    },
    sky300: {
        bg: 'bg-sky-300',
        color: '#7dd3fc',
        text: 'text-sky-300',
    },
    sky400: {
        bg: 'bg-sky-400',
        color: '#38bdf8',
        text: 'text-sky-400',
    },
    sky500: {
        bg: 'bg-sky-500',
        color: '#0ea5e9',
        text: 'text-sky-500',
    },
    sky600: {
        bg: 'bg-sky-600',
        color: '#0284c7',
        text: 'text-sky-500',
    },
    sky700: {
        bg: 'bg-sky-700',
        color: '#0369a1',
        text: 'text-sky-700',
    },
    sky800: {
        bg: 'bg-sky-800',
        color: '#075985',
        text: 'text-sky-800',
    },
    sky900: {
        bg: 'bg-sky-900',
        color: Colors.sky[900],
        text: 'text-sky-900',
    },
    teal100: {
        bg: 'bg-teal-100',
        color: Colors.teal[100],
        text: 'text-teal-100',
    },
    teal200: {
        bg: 'bg-teal-200',
        color: Colors.teal[200],
        text: 'text-teal-200',
    },
    teal300: {
        bg: 'bg-teal-300',
        color: '#5eead4',
        text: 'text-teal-300',
    },
    teal400: {
        bg: 'bg-teal-400',
        color: Colors.teal[400],
        text: 'text-teal-400',
    },
    teal500: {
        bg: 'bg-teal-500',
        color: '#14b8a6',
        text: 'text-teal-500',
    },
    teal600: {
        bg: 'bg-teal-600',
        color: '#0d9488',
        text: 'text-teal-600',
    },
    teal700: {
        bg: 'bg-teal-700',
        color: Colors.teal[700],
        text: 'text-teal-700',
    },
    teal800: {
        bg: 'bg-teal-800',
        color: '#115e59',
        text: 'text-teal-800',
    },
    teal900: {
        bg: 'bg-teal-900',
        color: '#134e4a',
        text: 'text-teal-900',
    },
    violet100: {
        bg: 'bg-violet-100',
        color: Colors.violet[100],
        text: 'text-violet-100',
    },
    violet200: {
        bg: 'bg-violet-200',
        color: Colors.violet[200],
        text: 'text-violet-200',
    },
    violet300: {
        bg: 'bg-violet-300',
        color: Colors.violet[300],
        text: 'text-violet-300',
    },
    violet400: {
        bg: 'bg-violet-400',
        color: Colors.violet[400],
        text: 'text-violet-400',
    },
    violet500: {
        bg: 'bg-violet-500',
        color: Colors.violet[500],
        text: 'text-violet-500',
    },
    violet600: {
        bg: 'bg-violet-600',
        color: Colors.violet[600],
        text: 'text-violet-600',
    },
    violet700: {
        bg: 'bg-violet-700',
        color: Colors.violet[700],
        text: 'text-violet-700',
    },
    violet800: {
        bg: 'bg-violet-800',
        color: Colors.violet[800],
        text: 'text-violet-800',
    },
    violet900: {
        bg: 'bg-violet-900',
        color: Colors.violet[900],
        text: 'text-violet-900',
    },
    white: {
        bg: 'bg-white',
        color: Colors.white,
        text: 'text-white',
    },
    yellow100: {
        bg: 'bg-yellow-100',
        color: Colors.yellow[100],
        text: 'text-yellow-100',
    },
    yellow200: {
        bg: 'bg-yellow-200',
        color: Colors.yellow[200],
        text: 'text-yellow-200',
    },
    yellow300: {
        bg: 'bg-yellow-300',
        color: Colors.yellow[300],
        text: 'text-yellow-300',
    },
    yellow400: {
        bg: 'bg-yellow-400',
        color: Colors.yellow[400],
        text: 'text-yellow-400',
    },
    yellow500: {
        bg: 'bg-yellow-500',
        color: Colors.yellow[500],
        text: 'text-yellow-500',
    },
    yellow600: {
        bg: 'bg-yellow-600',
        color: Colors.yellow[600],
        text: 'text-yellow-600',
    },
    yellow700: {
        bg: 'bg-yellow-700',
        color: Colors.yellow[700],
        text: 'text-yellow-700',
    },
    yellow800: {
        bg: 'bg-yellow-800',
        color: Colors.yellow[800],
        text: 'text-yellow-800',
    },
    yellow900: {
        bg: 'bg-yellow-900',
        color: Colors.yellow[900],
        text: 'text-yellow-900',
    },
    viridisYellow: {
        bg: '#fde725',
        color: '#fde725',
        text: '#fde725',
    },
    viridisLime: {
        bg: '#5ec962',
        color: '#5ec962',
        text: '#5ec962',
    },
    viridisGreen: {
        bg: '#21918c',
        color: '#21918c',
        text: '#21918c',
    },
    viridisBlue: {
        bg: '#3b528b',
        color: '#3b528b',
        text: '#3b528b',
    },
    viridisPurple: {
        bg: '#440154',
        color: '#440154',
        text: '#440154',
    },
    plutoPurple200: {
        bg: '#DBD0E8',
        color: '#DBD0E8',
        text: '#DBD0E8',
    },
    plutoPurple400: {
        bg: '#B7A1D2',
        color: '#B7A1D2',
        text: '#B7A1D2',
    },
    plutoPurple600: {
        bg: '#9373BC',
        color: '#9373BC',
        text: '#9373BC',
    },
    plutoPurple800: {
        bg: '#6F44A6',
        color: '#6F44A6',
        text: '#6F44A6',
    },
    plutoPurple: {
        bg: '#4B1590',
        color: '#4B1590',
        text: '#4B1590',
    },
});

export default colors;
