import { Organism, OrganismID } from '@models/Organism';
import { Project } from '@models/Project';
import { SimpleUser } from '@models/User';
import {
    ExperimentTypeShortname,
    ExperimentTypeSummary,
    MeasurementUnit,
    TargetType,
    TargetTypeShortname,
} from '@models/ExperimentType';
import { PermissionName, UserPermissionObject } from '@models/Permission';
import { PlutoIdentifiable, ShareLevel } from '@api/ApiTypes';
import { AdminMessage } from '@models/AdminMessage';
import ExperimentError from '@models/ExperimentError';
import PipelineRun from '@models/PipelineRun';
import { AcceptedWorkflow } from './Workflow';

export type BaseExperimentParams = {
    name: string;
    project_id?: string | null;
    organism: OrganismID | string | null;
    type: ExperimentTypeShortname | null;
    target_type?: TargetTypeShortname | null;
    /**
     * List of plot uuids to sort plots by
     */
    plot_display_order?: string[];
};

export type SaveExperimentParams = BaseExperimentParams & { uuid?: string };
export type EditExperimentParams = Pick<BaseExperimentParams, 'name' | 'project_id'> &
    Pick<
        Experiment,
        | 'external_url'
        | 'external_url_display_name'
        | 'description'
        | 'description_block'
        | 'report_url'
        | 'uploaded_insights_report_id'
    >;
export type EditLinkParams = Pick<Experiment, 'external_url' | 'external_url_display_name'>;

export type CopyExperimentParams = Pick<BaseExperimentParams, 'name' | 'project_id'> &
    Pick<Experiment, 'external_url' | 'external_url_display_name'> & {
        data_source: string;
    };
export type CopyExperimentFromWorkflowParams = Pick<BaseExperimentParams, 'name' | 'project_id'> &
    Pick<Experiment, 'external_url' | 'external_url_display_name' | 'description'>;

export enum ExperimentStatus {
    draft = 'draft',
    pending = 'pending',
    pending_complete = 'pending_complete',
    complete = 'complete',
    failed = 'failed',
    sample_failed = 'sample_failed',
    assay_failed = 'assay_failed',
    copying = 'copying',
}

export type ExperimentStatusGroup = 'draft' | 'in_progress' | 'complete' | 'error';
export const failedStatuses = [ExperimentStatus.failed, ExperimentStatus.sample_failed, ExperimentStatus.assay_failed];
export const experimentStatusDisplayOrder: ExperimentStatusGroup[] = ['draft', 'in_progress', 'complete', 'error'];

export type StatusGroupInfo = { value: ExperimentStatusGroup; display_name: string; statuses: ExperimentStatus[] };
export const experimentStatusGroupInfo: Record<ExperimentStatusGroup, StatusGroupInfo> = {
    draft: { display_name: 'Draft', statuses: [ExperimentStatus.draft], value: 'draft' },
    in_progress: {
        display_name: 'In Progress',
        statuses: [ExperimentStatus.pending, ExperimentStatus.pending_complete],
        value: 'in_progress',
    },
    complete: {
        display_name: 'Complete',
        statuses: [ExperimentStatus.complete],
        value: 'complete',
    },
    error: {
        display_name: 'Error',
        statuses: failedStatuses,
        value: 'error',
    },
};

export const getExperimentStatusGroupInfo = (status: ExperimentStatus): StatusGroupInfo | undefined => {
    return Object.values(experimentStatusGroupInfo).find((value) => {
        return value.statuses.includes(status);
    });
};

export const isExperimentFailedStatus = (status: ExperimentStatus | null | undefined) => {
    if (!status) {
        return false;
    }
    return failedStatuses.includes(status);
};

export const getFailedStatusMessage = (status: ExperimentStatus) => {
    switch (status) {
        case ExperimentStatus.assay_failed:
            return 'Failed to process assay data';
        case ExperimentStatus.failed:
            return 'An error occurred while processing this experiment';
        case ExperimentStatus.sample_failed:
            return 'Failed to process sample data';
        default:
            return null;
    }
};

export const incompleteExperimentStatuses: ExperimentStatus[] = [ExperimentStatus.draft, ExperimentStatus.pending];
export const hasConfigExperimentStatuses: ExperimentStatus[] = [ExperimentStatus.pending_complete];
export const hasPlotExperimentStatuses: ExperimentStatus[] = [ExperimentStatus.pending_complete];

export const plotsReadyStatus = (status?: ExperimentStatus | null): boolean => {
    if (!status) {
        return false;
    }
    return hasPlotExperimentStatuses.includes(status);
};

export type AssayDataUnits = { units?: MeasurementUnit | null; units_display_name?: string | null };
export type ReportQuality = 'good' | 'fair' | 'poor';
export default interface Experiment extends UserPermissionObject, PlutoIdentifiable {
    accepted_workflow: AcceptedWorkflow | null;
    admin_message?: AdminMessage | null;
    assay_data_units?: AssayDataUnits;
    assay_data_uploaded: boolean;
    copied_from_experiment_id?: string | null;
    created_at?: string;
    created_by?: SimpleUser | null;
    description?: string | null;
    errors?: ExperimentError[] | null;
    execution_report_url?: string | null;
    experiment_owner?: SimpleUser | null;
    external_url_display_name?: string | null;
    external_url?: string | null;
    fastqs_queued?: boolean;
    fastqs_uploaded?: boolean;
    insights_admin_phase: number | null;
    is_archived?: boolean;
    description_block?: string | null;
    merged_replicates?: boolean;
    name: string;
    organism: Organism;
    pipeline_run:
        | null
        | undefined
        | Pick<PipelineRun, 'uuid' | 'pipeline_status' | 'genome' | 'pipeline_version' | 'tasks'>;
    plot_count?: number;
    plot_display_order?: string[];
    pluto_id: string;
    project: Project | null;
    qc_report_quality?: ReportQuality | null;
    qc_report_url?: string | null;
    report_url?: string | null;
    uploaded_insights_report_id?: string | null;
    sample_data_uploaded: boolean;
    seurats_uploaded?: boolean;
    share_level: ShareLevel;
    short_display_name?: string;
    status: ExperimentStatus;
    target_type?: TargetType;
    type: ExperimentTypeSummary;
    updated_at?: string;
    user_permissions?: PermissionName[];
    uuid: string;
    workflow_order?: string[];
    experiment_bucket?: string;
}

export interface OrgExperiment extends Experiment {
    project_uuid: string;
    project_pluto_id: string;
    project_name: string;
    project_share_level: string;
}

export type ExperimentSortField = keyof Pick<Experiment, 'created_at' | 'updated_at'>;
export type ExperimentSortValue = ExperimentSortField | `-${ExperimentSortField}`;

/**
 * The different types of experiment data. This is used to determine what API Endpoint to upload data files to
 */
export enum ExperimentDataType {
    SAMPLE = 'SAMPLE',
    ASSAY = 'ASSAY',
}

/**
 * For a given experiment type, return the path slug for the API to be used for fetching / uploading the data files.
 * @param {ExperimentDataType} dataType
 * @return {string}
 */
export function getExperimentDataTypeSlug(dataType: ExperimentDataType): string {
    let slug: string;
    switch (dataType) {
        case ExperimentDataType.SAMPLE:
            slug = 'sample-data';
            break;
        case ExperimentDataType.ASSAY:
            slug = 'assay-data';
            break;
    }
    return slug;
}

export interface ExperimentSortOption {
    displayName: string;
    value: ExperimentSortValue;
}

export const experimentSortOptions: ExperimentSortOption[] = [
    { value: '-created_at', displayName: 'Date (Newest first)' },
    { value: 'created_at', displayName: 'Date (Newest last)' },
    { value: '-updated_at', displayName: 'Recently updated first' },
    { value: 'updated_at', displayName: 'Recently updated last' },
];

export type ExperimentStat = {
    count: number;
    type: string;
};

export type ExperimentStatResponse = {
    pipelines_run: { count: number };
    plots_created: { count: number };
    analysis_types: {
        count: number;
        items: ExperimentStat[];
    };
};

export type ExperimentShort = Pick<Experiment, 'uuid' | 'name' | 'pluto_id'>;

export const DESCRIPTION_CHARACTER_LIMIT = 25_000;
export const LINK_NAME_CHARACTER_LIMIT = 50;
